import {
  AssignmentIndOutlined,
  BoltOutlined,
  Close,
  ExpandMore,
  FilterListOutlined,
  GroupOutlined,
  Label,
  LabelOutlined,
  MarkChatReadOutlined,
  MarkChatUnreadOutlined,
  MoreVertOutlined,
  PersonAddAlt1Outlined,
  PowerSettingsNewOutlined,
  RadioButtonUnchecked,
  RemoveCircleOutline,
  RemoveCircleOutlineOutlined,
  SearchOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { Fragment, useEffect, useRef, useState } from "react";
import localizations from "../../localizations.json";
import { applicationType } from "../../applicationType";
import SendTemplateForm from "./SendTemplateForm";

const HeaderOutlinedButton = styled(({ matchesBigMobile, ...props }) => (
  <Button
    variant={props.variant ? props.variant : "outlined"}
    color="info"
    size="small"
    sx={{
      py: applicationType === "extension" || matchesBigMobile ? 0.75 : 1,
      px: applicationType === "extension" || matchesBigMobile ? 0.75 : 1,
      borderRadius: 0.75,
      borderWidth: "2px !important",
      borderStyle: "solid",
      height: applicationType === "extension" || matchesBigMobile ? 36 : 42,
      minWidth: 0,
      fontSize:
        applicationType === "extension" || matchesBigMobile ? 12 : undefined,
      color: "white",
      borderColor: "rgba(238, 238, 238, 0.30)",
      "&:hover": {
        borderColor: "common.white",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
      },
      "& .MuiButton-endIcon": {
        color: "white",
      },
      ...(props.variant === "contained" && {
        color: "primary.main",
        backgroundColor: "common.white",
        "&:hover": {
          backgroundColor: "grey.100",
        },
      }),
    }}
    {...props}
  />
))(({ theme }) => ({}));

function FunnerHeader({
  searchParams,
  searchParamsRef,
  handleGetContactsParams,
  getCompanyChatUsers,
  getCompanyChatUsersGlobal,
  updateUserConfig,
  isMyUsersChats,
  setMyUsersChat,
  chatUsersLabelsList,
  userData,
  funnerUsers,
  setShowUsersCreateChat,
  toggleUnreadMessagesPopup,
  selectedChatUsersList,
  bulkChatUserActions,
  templatesList,
  handleSetNotif,
  fetchWhatsappTemplates,
  chatUsersGlobalRef,
}) {
  const theme = useTheme();
  const [localization, setLocalization] = useState(
    localizations["he"].chat.chatHeader,
  );
  const matchesBigMobile = useMediaQuery(theme.breakpoints.down(768));
  const matchesMobile = useMediaQuery(theme.breakpoints.down(480));

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);
  const [menuType, setMenuType] = useState(null);
  const [contactsFilter, setContactsFilter] = useState();

  const [sendTemplatePopup, setSendTemplatePopup] = useState(false);

  //! Search states
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bulkAction, setBulkAction] = useState(null);

  const timeoutId = useRef(null);
  const additionalMenuBtnRef = useRef(null);
  const pulsateInterval = useRef(null);

  //! Menu handlers
  const handleMenuEl = (e, menu) => {
    setAnchorMenuEl(e.currentTarget);
    setMenuType(menu);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
    setTimeout(() => {
      setBulkAction(null);
    }, 200);
  };

  const handleCloseSendTemplatePopup = () => {
    setSendTemplatePopup(false);
  };

  //! Search handlers
  const handleSearchInput = (event) => {
    const { value } = event.target;
    setQuery(value);
    handleSearchQuery(value);
  };
  const handleSearchQuery = (value) => {
    clearTimeout(timeoutId.current);

    timeoutId.current = setTimeout(async () => {
      if (value.trim() !== previousQuery.trim()) {
        console.log(`Searching for: ${value}`);

        setPreviousQuery(value);
        searchParamsRef.current.pageOffset = 1;
        searchParamsRef.current.searchQuery = value;
        setIsLoading(true);
        await getCompanyChatUsers();
        setIsLoading(false);
      }
    }, 1500);
  };
  const clearSearch = async () => {
    setIsLoading(true);
    searchParamsRef.current.pageOffset = 1;
    setQuery("");
    searchParamsRef.current.searchQuery = "";
    await getCompanyChatUsers();
    setIsLoading(false);
  };
  function handleIsOnlyUnread() {
    const prevState = searchParamsRef.current.isOnlyUnread;
    handleGetContactsParams({
      isOnlyUnread: !prevState,
    });
    updateUserConfig("unreadFilter", !prevState, false);
    getCompanyChatUsers();
  }
  const handleSelectChatOpenFilterOption = (value) => {
    handleMenuClose();
    handleGetContactsParams({
      pageOffset: 1,
      open: value,
    });
    updateUserConfig("openCloseFilter", value, false);
    setTimeout(() => {
      getCompanyChatUsers();
    }, 0);
  };
  const handleSelectLabelsFilterOption = (option) => {
    handleMenuClose();
    handleGetContactsParams({
      pageOffset: 1,
      label:
        option === "noFilter"
          ? ""
          : option.name ===
            localizations["he"].chat.chatHeader.filterLabel.noLabel
          ? "noLabel"
          : JSON.stringify(option),
    });

    setTimeout(() => {
      getCompanyChatUsers();
    }, 0);
  };
  const handleIsMyState = (state = null) => {
    const newMyUsersState = state !== null ? state : !isMyUsersChats;
    setMyUsersChat(newMyUsersState);
    setContactsFilter(
      newMyUsersState ? localization.myChatUsers : localization.notMyChatUsers,
    );
    clearAssigneeFilter();
    handleMenuClose();
  };
  const handleAssigneeFilterState = () => {
    setContactsFilter(localization.filterByAssigneeBtn);
  };

  const clearAssigneeFilter = () => {
    handleGetContactsParams({ assigneeFilter: [] });
    getCompanyChatUsers();
  };
  const selectAllInAssigneeList = () => {
    const newArr = funnerUsers.map((user) => user.Udid);
    handleGetContactsParams({ assigneeFilter: [...newArr] });
    getCompanyChatUsers();
  };
  const toggleThisUserInAssigneeList = (userglobalId) => {
    if (searchParamsRef.current.assigneeFilter.includes(userglobalId)) {
      searchParamsRef.current.assigneeFilter = handleGetContactsParams({
        assigneeFilter: searchParamsRef.current.assigneeFilter.filter(
          (userId) => userId !== userglobalId,
        ),
      });
      getCompanyChatUsers();
    } else {
      handleGetContactsParams({
        assigneeFilter: [
          ...searchParamsRef.current.assigneeFilter,
          userglobalId,
        ],
      });
      getCompanyChatUsers();
    }
  };

  const pulsateBtn = (ref) => {
    ref.current.start();
    setTimeout(() => {
      ref.current.stop();
    }, 1000);
  };

  useEffect(() => {
    const isShowAllChatUsers =
      userData?.IsShowAllChatUsers || userData?.IsShowAllChatUsers === null;

    if (isShowAllChatUsers) {
      setContactsFilter(localization.notMyChatUsers);
    } else {
      setContactsFilter(localization.filterByAssigneeBtn);
    }
  }, []);

  useEffect(() => {
    if (selectedChatUsersList?.length > 1) {
      if (!pulsateInterval.current) {
        pulsateBtn(additionalMenuBtnRef);
        pulsateInterval.current = setInterval(() => {
          pulsateBtn(additionalMenuBtnRef);
        }, 2000);
      }
    } else {
      clearInterval(pulsateInterval.current);
    }
  }, [selectedChatUsersList]);

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "primary.main",
            height:
              applicationType === "extension" || matchesBigMobile ? 65 : 72,
            display: "flex",
            alignItems: "center",
            px: applicationType === "extension" || matchesBigMobile ? 1 : 1.5,

            [theme.breakpoints.down(768)]: {
              pl: 7,
            },
          }}
        >
          <Grid
            container
            spacing={
              applicationType === "extension" || matchesBigMobile ? 1 : 1.5
            }
            alignItems={"center"}
            wrap="nowrap"
          >
            <Grid item>
              <Tooltip title={contactsFilter}>
                <Box>
                  <HeaderOutlinedButton
                    endIcon={<ExpandMore />}
                    matchesBigMobile={matchesBigMobile}
                    onClick={(e) => handleMenuEl(e, "contacts")}
                    startIcon={
                      matchesMobile &&
                      (contactsFilter === localization.myChatUsers ? (
                        <AssignmentIndOutlined />
                      ) : contactsFilter === localization.notMyChatUsers ? (
                        <GroupOutlined />
                      ) : null)
                    }
                  >
                    <Box
                      component={"span"}
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        maxWidth: applicationType === "extension" ? 50 : 60,
                      }}
                    >
                      {!(
                        matchesMobile &&
                        contactsFilter !== localization.filterByAssigneeBtn
                      ) && contactsFilter}
                      {contactsFilter === localization.filterByAssigneeBtn &&
                        searchParams.assigneeFilter.length > 0 && (
                          <IconButton
                            sx={{ color: "error.light" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleIsMyState(false);
                            }}
                          >
                            <Close />
                          </IconButton>
                        )}
                    </Box>
                  </HeaderOutlinedButton>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  searchParams.label === ""
                    ? localization.filterLabel.tooltip
                    : searchParams.label === "noLabel"
                    ? localizations["he"].chat.chatHeader.filterLabel.noLabel
                    : JSON.parse(searchParams.label).name
                }
              >
                <Box>
                  <HeaderOutlinedButton
                    matchesBigMobile={matchesBigMobile}
                    startIcon={
                      searchParams.label === "noLabel" ? (
                        <Label sx={{ color: "common.white" }} />
                      ) : searchParams.label === "" ? (
                        <LabelOutlined />
                      ) : (
                        <Label
                          sx={{ color: JSON.parse(searchParams.label).color }}
                        />
                      )
                    }
                    endIcon={<ExpandMore />}
                    onClick={(e) => handleMenuEl(e, "label")}
                  >
                    {!matchesMobile && (
                      <Box
                        component={"span"}
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth:
                            applicationType === "extension" || matchesBigMobile
                              ? 45
                              : 50,
                        }}
                      >
                        {searchParams.label === "noLabel"
                          ? localizations["he"].chat.chatHeader.filterLabel
                              .noLabel
                          : searchParams.label === ""
                          ? localizations["he"].chat.chatHeader.filterLabel
                              .noFilter
                          : JSON.parse(searchParams.label).name}
                      </Box>
                    )}
                  </HeaderOutlinedButton>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  searchParams.open === "open"
                    ? localization.filterOpenClose.openTooltip
                    : searchParams.open === "close"
                    ? localization.filterOpenClose.closeTooltip
                    : localization.filterOpenClose.noFilterTooltip
                }
              >
                <Box>
                  <HeaderOutlinedButton
                    endIcon={<ExpandMore />}
                    matchesBigMobile={matchesBigMobile}
                    onClick={(e) => handleMenuEl(e, "openStatus")}
                  >
                    {searchParams.open === "open" ||
                    searchParams.open === "close" ? (
                      <PowerSettingsNewOutlined
                        sx={{
                          color:
                            searchParams.open === "open"
                              ? "#00C667"
                              : "error.light",
                        }}
                      />
                    ) : (
                      <RadioButtonUnchecked
                        sx={{ color: "grey.400", width: 20, height: 20 }}
                      />
                    )}
                  </HeaderOutlinedButton>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip
                title={
                  <Box
                    dangerouslySetInnerHTML={{
                      __html: searchParams.isOnlyUnread
                        ? localizations["he"].chat.chatHeader.filterUnread
                            .filtered
                        : localizations["he"].chat.chatHeader.filterUnread
                            .notFiltered,
                    }}
                  />
                }
              >
                <Box>
                  <HeaderOutlinedButton
                    variant={
                      searchParams.isOnlyUnread ? "contained" : "outlined"
                    }
                    matchesBigMobile={matchesBigMobile}
                    onClick={handleIsOnlyUnread}
                  >
                    <FilterListOutlined />
                  </HeaderOutlinedButton>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item flexGrow={1} sx={{ px: "0 !important" }}></Grid>
            <Grid item>
              <Box sx={{ display: "flex", gap: 0.5 }}>
                <IconButton
                  touchRippleRef={additionalMenuBtnRef}
                  sx={{
                    color: "white",
                    "&:hover": {
                      bgcolor: "rgba(255, 255, 255, 0.25)",
                    },
                    "& .MuiTouchRipple-child": {
                      backgroundColor: "white",
                    },
                    width: 30,
                    height: 30,
                  }}
                  size="small"
                  onClick={(e) => handleMenuEl(e, "additional")}
                >
                  <MoreVertOutlined />
                </IconButton>
                {applicationType === "extension" && (
                  <IconButton
                    sx={{
                      color: "white",
                      "&:hover": {
                        bgcolor: "rgba(255, 255, 255, 0.25)",
                      },
                      "& .MuiTouchRipple-child": {
                        backgroundColor: "white",
                      },
                      width: 30,
                      height: 30,
                    }}
                    size="small"
                    onClick={(e) => toggleUnreadMessagesPopup()}
                  >
                    <Close />
                  </IconButton>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            bgcolor: "grey.200",
            height:
              applicationType === "extension" || matchesBigMobile ? 60 : 85,
            display: "flex",
            alignItems: "center",
            px: applicationType === "extension" || matchesBigMobile ? 1 : 2.5,
            py: applicationType === "extension" || matchesBigMobile ? 1 : 2,
          }}
        >
          <TextField
            placeholder="הקלד שם לקוח או איש קשר לחיפוש"
            onChange={handleSearchInput}
            value={query}
            fullWidth
            size={
              applicationType === "extension" || matchesBigMobile
                ? "small"
                : "medium"
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchOutlined />
                </InputAdornment>
              ),
              endAdornment: query.length > 0 && (
                <InputAdornment position="end">
                  <IconButton onClick={clearSearch}>
                    <Close sx={{ color: "error.main" }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ bgcolor: "common.white" }}
          />
        </Box>
      </Grid>
      {isLoading && (
        <Grid item xs={12}>
          <LinearProgress color="secondary" />
        </Grid>
      )}

      <Menu
        anchorEl={anchorMenuEl}
        open={Boolean(anchorMenuEl)}
        onClose={handleMenuClose}
        sx={{ mt: 0.75, maxHeight: 450 }}
        TransitionProps={{ onExited: () => setMenuType(null) }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: menuType === "additional" ? "left" : "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: menuType === "additional" ? "left" : "right",
        }}
        disableScrollLock={true}
      >
        {menuType === "contacts" && [
          (userData?.IsShowAllChatUsers === true ||
            userData?.IsShowAllChatUsers === null) && (
            <MenuItem
              key="allChats"
              onClick={() => {
                handleIsMyState();
              }}
            >
              {isMyUsersChats
                ? localization.notMyChatUsers
                : localization.myChatUsers}
            </MenuItem>
          ),
          contactsFilter === localization.filterByAssigneeBtn ? (
            [
              searchParams.assigneeFilter.length < funnerUsers?.length && (
                <MenuItem
                  onClick={(e) => selectAllInAssigneeList()}
                  key="selectAllInAssigneeFilter"
                >
                  {localizations["he"].chat.chatHeader.assigneeSelectAll}
                </MenuItem>
              ),
              searchParams.assigneeFilter.length > 0 && (
                <MenuItem
                  onClick={(e) => clearAssigneeFilter()}
                  key="clearAllInAssigneeFilter"
                >
                  {
                    localizations["he"].chat.chatHeader
                      .removeFilterByAssigneeBtn
                  }
                </MenuItem>
              ),
              <MenuItem
                selected={searchParams.assigneeFilter.includes("no-assign")}
                onClick={(e) => toggleThisUserInAssigneeList("no-assign")}
                key="noAssignInAssigneeFilter"
              >
                {localizations["he"].chat.chatHeader.assigneeNoAsign}
              </MenuItem>,
              funnerUsers
                ?.filter(
                  (user) =>
                    user.FullName !== null && user.FullName !== undefined,
                )
                .map((user) => (
                  <MenuItem
                    onClick={(e) => toggleThisUserInAssigneeList(user.Udid)}
                    selected={searchParams.assigneeFilter.includes(user.Udid)}
                    key={user.Udid}
                  >
                    {user.FullName}
                  </MenuItem>
                )),
            ]
          ) : (
            <MenuItem
              key="contactsFilter"
              onClick={() => handleAssigneeFilterState()}
            >
              {localization.filterByAssigneeBtn}
            </MenuItem>
          ),
        ]}
        {menuType === "label" && [
          <MenuItem
            key={"no-filter"}
            sx={{ color: "primary.main" }}
            onClick={() => handleSelectLabelsFilterOption("noFilter")}
          >
            <LabelOutlined sx={{ color: "primary.main", mr: 1.5 }} />
            {localization.filterLabel.noFilter}
          </MenuItem>,
          chatUsersLabelsList.map((label) => (
            <MenuItem
              key={label.name}
              sx={{ color: "primary.main" }}
              onClick={() => handleSelectLabelsFilterOption(label)}
            >
              <Label sx={{ color: label.color, mr: 1.5 }} />
              {label.name}
            </MenuItem>
          )),
        ]}
        {menuType === "openStatus" && [
          <Tooltip
            title={localization.filterOpenClose.noFilterTooltip}
            placement="left"
            key="openFilterAll"
          >
            <MenuItem
              sx={{ color: "primary.main" }}
              onClick={() => handleSelectChatOpenFilterOption("all")}
            >
              {localizations["he"].chat.chatHeader.filterOpenClose.noFilter}
            </MenuItem>
          </Tooltip>,
          <Tooltip
            title={localization.filterOpenClose.openTooltip}
            placement="left"
            key="openFilterOpen"
          >
            <MenuItem
              sx={{ color: "primary.main" }}
              onClick={() => handleSelectChatOpenFilterOption("open")}
            >
              {localizations["he"].chat.chatHeader.filterOpenClose.open}
            </MenuItem>
          </Tooltip>,
          <Tooltip
            title={localization.filterOpenClose.closeTooltip}
            placement="left"
            key="openFilterClose"
          >
            <MenuItem
              sx={{ color: "primary.main" }}
              onClick={() => handleSelectChatOpenFilterOption("close")}
            >
              {localizations["he"].chat.chatHeader.filterOpenClose.close}
            </MenuItem>
          </Tooltip>,
        ]}
        {menuType === "additional" &&
          (bulkAction
            ? bulkAction === "openStatus"
              ? [
                  <MenuItem
                    onClick={() => {
                      bulkChatUserActions("openCloseConvStatus", false);
                      handleMenuClose();
                    }}
                    key={"close"}
                  >
                    <ListItemIcon>
                      <PowerSettingsNewOutlined sx={{ color: "error.light" }} />
                    </ListItemIcon>
                    <ListItemText>
                      {
                        localization.additionalMenu.bulkActions
                          .openCloseConvStatus.makeClose
                      }
                    </ListItemText>
                  </MenuItem>,
                  <MenuItem
                    onClick={() => {
                      bulkChatUserActions("openCloseConvStatus", true);
                      handleMenuClose();
                    }}
                    key={"open"}
                  >
                    <ListItemIcon>
                      <PowerSettingsNewOutlined sx={{ color: "#00C667" }} />
                    </ListItemIcon>
                    <ListItemText>
                      {
                        localization.additionalMenu.bulkActions
                          .openCloseConvStatus.makeOpen
                      }
                    </ListItemText>
                  </MenuItem>,
                ]
              : bulkAction === "label"
              ? chatUsersLabelsList.map((label) => (
                  <MenuItem
                    key={label.name}
                    sx={{ color: "primary.main" }}
                    onClick={() => {
                      bulkChatUserActions("setLabel", label);
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <Label sx={{ color: label.color, mr: 1.5 }} />
                    </ListItemIcon>
                    <ListItemText>{label.name}</ListItemText>
                  </MenuItem>
                ))
              : bulkAction === "changeAssignee"
              ? funnerUsers.map((user) => (
                  <MenuItem
                    key={user.Udid}
                    onClick={() => {
                      bulkChatUserActions("changeAssignee", user.Udid);
                      handleMenuClose();
                    }}
                  >
                    <Avatar
                      alt={user.FullName}
                      color="grey.400"
                      sx={{
                        width: "24px !important",
                        height: "24px !important",
                        fontSize: "12px !important",
                        mr: 1.5,
                      }}
                    >
                      {user.FullName.split(" ").length > 0 &&
                        `${user.FullName.split(" ")[0].slice(0, 1)}${
                          user.FullName.split(" ")[1]
                            ? user.FullName.split(" ")[1].slice(0, 1)
                            : ""
                        }`}
                    </Avatar>
                    {user.FullName}
                  </MenuItem>
                ))
              : null
            : [
                <Tooltip
                  title={localizations["he"].chat.createUsers.tooltip}
                  key="additionalHeaderMenuCreateUser"
                >
                  <MenuItem
                    onClick={() => {
                      setShowUsersCreateChat(true);
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <PersonAddAlt1Outlined />
                    </ListItemIcon>
                    <ListItemText>
                      {localization.additionalMenu.createUser}
                    </ListItemText>
                  </MenuItem>
                </Tooltip>,
                <MenuItem
                  key={"sendTemplate"}
                  onClick={() => {
                    setSendTemplatePopup(true);
                    handleMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <BoltOutlined />
                  </ListItemIcon>
                  <ListItemText>
                    {localization.additionalMenu.sendTemplate}
                  </ListItemText>
                </MenuItem>,
                // <MenuItem
                //   key="additionalHeaderMenuCloseConv"
                //   onClick={() => {
                //     handleMenuClose();
                //   }}
                // >
                //   <ListItemIcon>
                //     <PersonAddAlt1Outlined />
                //   </ListItemIcon>
                //   <ListItemText>
                //     {localization.additionalMenu.closeConversation}
                //   </ListItemText>
                // </MenuItem>,
                selectedChatUsersList.length > 1 && [
                  <Divider />,
                  <ListSubheader
                    key={"title"}
                    sx={{
                      color: "primary.main",
                      fontSize: "16px !important",
                      fontWeight: "700 !important",
                      lineHeight: "36px !important",
                    }}
                  >
                    {localization.additionalMenu.bulkActions.title}
                  </ListSubheader>,
                  <MenuItem
                    onClick={() => {
                      setBulkAction("openStatus");
                    }}
                  >
                    <ListItemIcon>
                      <PowerSettingsNewOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      {
                        localization.additionalMenu.bulkActions
                          .openCloseConvStatus.title
                      }
                    </ListItemText>
                  </MenuItem>,
                  <MenuItem
                    onClick={() => {
                      setBulkAction("label");
                    }}
                  >
                    <ListItemIcon>
                      <LabelOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      {localization.additionalMenu.bulkActions.setLabel}
                    </ListItemText>
                  </MenuItem>,
                  <MenuItem
                    onClick={() => {
                      setBulkAction("changeAssignee");
                    }}
                  >
                    <ListItemIcon>
                      <SupervisorAccountOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      {localization.additionalMenu.bulkActions.changeAssignee}
                    </ListItemText>
                  </MenuItem>,
                  <MenuItem
                    onClick={() => {
                      bulkChatUserActions("setRead");
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <MarkChatReadOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      {localization.additionalMenu.bulkActions.setRead}
                    </ListItemText>
                  </MenuItem>,
                  <MenuItem
                    onClick={() => {
                      bulkChatUserActions("setUnread");
                      handleMenuClose();
                    }}
                  >
                    <ListItemIcon>
                      <MarkChatUnreadOutlined />
                    </ListItemIcon>
                    <ListItemText>
                      {localization.additionalMenu.bulkActions.setUnread}
                    </ListItemText>
                  </MenuItem>,
                ],
              ])}
      </Menu>

      <SendTemplateForm
        open={sendTemplatePopup}
        onClose={handleCloseSendTemplatePopup}
        templatesList={templatesList}
        userData={userData}
        handleSetNotif={handleSetNotif}
        fetchWhatsappTemplates={fetchWhatsappTemplates}
        chatUsersGlobalRef={chatUsersGlobalRef}
        getCompanyChatUsers={getCompanyChatUsers}
        getCompanyChatUsersGlobal={getCompanyChatUsersGlobal}
      />
    </Grid>
  );
}

export default FunnerHeader;
