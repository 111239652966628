import localizations from "../localizations.json";

export const implementedVars = [
  {
    varName:
      localizations["he"].implementedVariables["$contact.accountid"].name,
    label: localizations["he"].implementedVariables["$contact.accountid"].label,
  },
  {
    varName:
      localizations["he"].implementedVariables["$contact.last_incoming_message"]
        .name,
    label:
      localizations["he"].implementedVariables["$contact.last_incoming_message"]
        .label,
  },
  {
    varName:
      localizations["he"].implementedVariables["$contact.accountname"].name,
    label:
      localizations["he"].implementedVariables["$contact.accountname"].label,
  },
  {
    varName: localizations["he"].implementedVariables["$contact.phone"].name,
    label: localizations["he"].implementedVariables["$contact.phone"].label,
  },
  {
    varName: localizations["he"].implementedVariables["$contact.contactd"].name,
    label: localizations["he"].implementedVariables["$contact.contactd"].label,
  },
  {
    varName:
      localizations["he"].implementedVariables["$contact.firstName"].name,
    label: localizations["he"].implementedVariables["$contact.firstName"].label,
  },
];
