import React, { useEffect, useState } from "react";
import { dateRangeIcon, download, taskIcon } from "../../../libs/images";
import { IsJsonString } from "../../../libs/helpers";
import localizations from "../../../localizations.json";
import { Download } from "@mui/icons-material";

function MessagesContextMenu({
  messagesContextRef,
  messagesContextState,
  closeMessagesContext,
  handleSendReply,
  selectedChatUser,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.messages.context,
  );
  const mediaTypes = [
    "document",
    "docx",
    "image",
    "video",
    "pdf",
    "xlsx",
    "zip",
  ];
  const isMediaFileType = mediaTypes.includes(
    messagesContextState.message.FileType,
  );
  let downloadFile;
  if (isMediaFileType) {
    const fileUrl = messagesContextState.message.File;
    const fileUrlParts = fileUrl.split("/");
    const fileName = fileUrlParts[fileUrlParts.length - 1];

    downloadFile = {
      url: fileUrl,
      name: fileName,
    };
  }

  function copyToClipboard(message) {
    let text = "";
    if (message?.FileType === "template") {
      if (IsJsonString(message.Message)) {
        const templateObject = JSON.parse(message.Message);
        text = templateObject["BODY"].text;
      } else {
        text = message.Message;
      }
    } else {
      text = message.Message;
    }

    navigator.clipboard.writeText(text).then(
      function () {
        console.log("Copying to clipboard was successful!");
      },
      function (err) {
        console.error("Could not copy text: ", err);
      },
    );
    closeMessagesContext();
  }
  function handleReply(message) {
    handleSendReply({
      isReply: true,
      message: message,
      userName: selectedChatUser.FullName,
    });

    closeMessagesContext();
  }

  const handleDownload = (url, fileName) => {
    // fetch(url)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.download = fileName || "downloaded-file";
    //     document.body.appendChild(link);

    //     link.click();

    //     document.body.removeChild(link);
    //     window.URL.revokeObjectURL(url);
    //   })
    //   .catch((error) => {
    //     console.error("Error fetching the file:", error);
    //   }).finally(() => {
    //     closeMessagesContext();
    //   });

    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    link.download = fileName || "video.mp4";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    closeMessagesContext();
  };

  return (
    <div
      className="context-menu__content active"
      ref={messagesContextRef}
      style={{
        left: messagesContextState.coords?.x,
        top: messagesContextState.coords?.y,
      }}
    >
      <ul className="context-menu__actions">
        <li className="context-menu__actions-item context-menu__emojy">
          <button
            type="button"
            className="context-menu__btn context-menu__btn--reply-text"
            onClick={() => handleReply(messagesContextState.message)}
          >
            <img src={taskIcon} alt="" />
            {localization.reply}
          </button>
        </li>
        <li className="context-menu__actions-item context-menu__emojy reactions">
          <button
            type="button"
            className="context-menu__btn reactions-toggle reply-text"
            onClick={() => handleReply(messagesContextState.message)}
          >
            <img src={dateRangeIcon} alt="" />
            {localization.replyWithReaction}
          </button>
          <ul className="context-reply-emojy smile-to-react__list context-smiles">
            <li className="smile-to-react__item">👍</li>
            <li className="smile-to-react__item">🙂</li>
            <li className="smile-to-react__item">🔥</li>
            <li className="smile-to-react__item">❤️</li>
            <li className="smile-to-react__item">😂</li>
            <li className="smile-to-react__item smile-to-react__item_plus reply_emoji">
              +
            </li>
          </ul>
        </li>
        <li className="context-menu__actions-item context-menu__emojy reactions">
          <button
            type="button"
            id="copy-text-button"
            className="context-menu__btn"
            onClick={(e) => copyToClipboard(messagesContextState.message)}
          >
            <img src={dateRangeIcon} alt="" />
            <span id="copy-text-message-button-label">{localization.copy}</span>
          </button>
        </li>
        {isMediaFileType && (
          <li className="context-menu__actions-item context-menu__emojy reactions">
            <button
              id="download-file-button"
              className="context-menu__btn"
              onClick={() =>
                handleDownload(downloadFile.url, downloadFile.name)
              }
              // target="_blank" rel="noreferrer"
            >
              <Download sx={{ opacity: 0.54, width: 20, height: 20 }} />
              {localization.download}
            </button>
          </li>
        )}

        {/* Uncomment when will be ready
<li class='context-menu__actions-item'>
  <button type='button' id='delete-message-button' class='context-menu__btn'>
    <img src='../../../assets/img/msg-popover/DeleteOutlined.svg' alt='' />
    <span>
      Delete message
    </span>
  </button>
</li>
*/}
      </ul>
    </div>
  );
}

export default MessagesContextMenu;
