import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
  Box,
  DialogActions,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TemplatesMenu from "../Templates/TemplatesMenu";
import { phoneRegex } from "../../libs/regexes";
import { UseFetchPost } from "../../hooks/fetchFunctions";
import localizations from "../../localizations.json";

function SendTemplateForm({
  open,
  onClose,
  templatesList,
  userData,
  handleSetNotif,
  fetchWhatsappTemplates,
  chatUsersGlobalRef,
  getCompanyChatUsers,
  getCompanyChatUsersGlobal,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].chat.chatHeader.sendTemplateForm,
  );

  const [sendTemplatePhone, setSendTemplatePhone] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateSending, setTemplateSending] = useState(false);

  const selectedTemplateRef = useRef(null);

  const handleClose = () => {
    onClose();
    setSendTemplatePhone("");
    handleSelectedTemplate(null);
  };

  const handleSelectedTemplate = (template) => {
    setSelectedTemplate(template);
    selectedTemplateRef.current = template;
  };

  const onSubmitForm = async () => {
    try {
      setTemplateSending(true);

      const sendTemplateObject = { ...selectedTemplateRef.current };

      let targetPhone = phoneRegex.test(sendTemplatePhone)
        ? "972" + sendTemplatePhone.slice(1)
        : sendTemplatePhone;
      const existingUser = chatUsersGlobalRef.current.notMyChats?.find(
        (user) => user.UserDisplayPhoneNumber === targetPhone,
      );

      if (!existingUser) {
        const createUserParams = {
          firstName: "",
          lastName: "",
          phone: targetPhone,
          isFireberry: false,
          // phoneType: selectedPhoneNumberType,
        };

        const result = await UseFetchPost(
          "/api/services/CreateChatUser",
          createUserParams,
          "config",
        );
        if (result?.data?.Id) {
          await getCompanyChatUsers();
          await getCompanyChatUsersGlobal();
        } else {
          return handleSetNotif(localization.userCreateErr, "error");
        }
      }

      sendTemplateObject.to = targetPhone;
      sendTemplateObject.frontTemplateObj = JSON.stringify({
        ...JSON.parse(sendTemplateObject.frontTemplateObj),
        to: targetPhone,
      });

      console.log("send template phone: ", sendTemplatePhone);
      console.log("selected template: ", sendTemplateObject);

      const res = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        sendTemplateObject,
        "messages",
      );
      if (res?.data?.result) {
        handleSetNotif(localization.sendSuccess, "success");
        handleClose();
      } else {
        handleSetNotif(localization.sendErr, "error");
      }
    } catch (error) {
      console.log("submit send template error: ", error);
    } finally {
      setTemplateSending(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"sm"}>
      <DialogTitle>{localization.title}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} flexDirection={"column"} sx={{ pt: 1 }}>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <TextField
              value={sendTemplatePhone}
              onChange={(e) => setSendTemplatePhone(e.target.value)}
              type="text"
              fullWidth
              label={localization.phoneLabel}
              variant="outlined"
            />
          </Grid>
          {/* <Grid item xs={12}>
              <div className="create-new-task-messaging__variables">
                <div
                  className="create-new-task-messaging__variables-header"
                  onClick={toggleShowVars}
                >
                  <div className="create-new-task-messaging__variables-header-text">
                    {localization.fromFile.withTemplate.implementedVarsTitle}
                  </div>
                  <div className="create-new-task-messaging__variables-header-plus"></div>
                </div>
                {showVars && implementedVars?.length && (
                  <div className="create-new-task-messaging__variables-list">
                    {implementedVars.map((variable, index) => (
                      <div
                        className="create-new-task-messaging__variable"
                        key={variable.varName}
                      >
                        {variable.varName}
                        <Tippy
                          content={<p>{variable.tooltipContent}</p>}
                          {...tippyPrefs}
                        >
                          <div className="create-new-task-messaging__variable-tooltip">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              x="0px"
                              y="0px"
                              width={100}
                              height={100}
                              viewBox="0 0 50 50"
                            >
                              <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z" />
                            </svg>
                          </div>
                        </Tippy>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Grid> */}
          <Grid item xs={12} flexGrow={1} sx={{ width: "100%" }}>
            <Box sx={{ pt: 2, maxHeight: "60vh", overflow: "auto" }}>
              <TemplatesMenu
                templatesLoading={false}
                templatesList={templatesList.templates}
                whatsappTemplatesList={templatesList.whatsappTemplates}
                languages={templatesList.languages}
                closeAccordion={() => {}}
                chatUserName={""}
                currentContact={""}
                globalID={userData.Udid}
                handleSetNotif={handleSetNotif}
                setTextareaValue={() => {}}
                textareaRef={null}
                resetTextArea={() => {}}
                templatesWithSend={false}
                noSendHandleFunc={(templatesObj) => {
                  handleSelectedTemplate(templatesObj.sendObject);
                }}
                fetchWhatsappTemplates={fetchWhatsappTemplates}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2,
        }}
      >
        <Button variant="outlined" color="primary" onClick={handleClose}>
          {localization.close}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!sendTemplatePhone.length || !selectedTemplate}
          onClick={() => onSubmitForm()}
          sx={{
            position: "relative",
            pointerEvents: templateSending ? "none" : "all",
            opacity: templateSending ? 0.75 : 1,
          }}
          endIcon={
            templateSending ? (
              <CircularProgress size={14} color="customWhite" />
            ) : null
          }
        >
          {localization.send}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SendTemplateForm;
