//* Object, that contains priority invoice page types and their titles
export const priorityPageTypes = [
  {
    key: "CPROF",
    sendChatShortName: "CP",
    sendChatTitle: "הצעות מחיר ללקוח",
    titles: [
      {
        lang: "he",
        pageTitle: "הצעות מחיר ללקוח",
        inputTitle: "מס' הצעת מחיר",
      },
    ],
  },
  {
    key: "ORDERS",
    sendChatShortName: "OR",
    sendChatTitle: "הזמנות לקוח",
    titles: [
      {
        lang: "he",
        pageTitle: "הזמנות לקוח",
        // inputTitle: "תפקיד",
        inputTitle: "הזמנה",
      },
    ],
  },
  {
    key: "AINVOICES",
    sendChatShortName: "AI",
    sendChatTitle: "הדפסת חשבונית מס",
    titles: [
      {
        lang: "en",
        pageTitle: "Sales Invoices",
        inputTitle: "Invoice No.",
      },
      {
        lang: "he",
        pageTitle: "חשבוניות מס",
        inputTitle: "חשבונית",
      },
    ],
  },
  {
    key: "TINVOICES",
    sendChatShortName: "TI",
    sendChatTitle: "הדפסת קבלה",
    titles: [
      {
        lang: "he",
        pageTitle: "קבלות",
        inputTitle: "קבלה",
      },
    ],
  },
  {
    key: "EINVOICES",
    sendChatShortName: "EI",
    sendChatTitle: "הדפסת חשבונית מס/קבלה",
    titles: [
      {
        lang: "he",
        pageTitle: "חשבוניות מס קבלה",
        inputTitle: "חשבונית",
      },
    ],
  },
  {
    key: "RINVOICES",
    sendChatShortName: "RI",
    sendChatTitle: "הדפסת חשבונית עסקה",
    titles: [
      {
        lang: "he",
        pageTitle: "חשבוניות עסקה",
        inputTitle: "חשבונית",
      },
    ],
  },
  // {
  //   key: "CINVOICES",
  //   sendChatShortName: "CI",
  //   titles: [
  //     {
  //       lang: "he",
  //       pageTitle: "חשבוניות מרכזות",
  //       inputTitle: "חשבונית",
  //     },
  //   ],
  // },
  // {
  //   key: "DOCUMENTS_D",
  //   sendChatShortName: "DD",
  //   titles: [
  //     {
  //       lang: "he",
  //       pageTitle: "משלוחים ללקוח",
  //       inputTitle: "תעודה",
  //     },
  //   ],
  // },
  // {
  //   key: "UINVOICES",
  //   sendChatShortName: "UI",
  //   titles: [
  //     {
  //       lang: "he",
  //       pageTitle: "החזרות תשלום",
  //       inputTitle: "מס. תעודה",
  //     },
  //   ],
  // },
];
//* Procedure name by page type
export const procedureNameByType = {
  AINVOICES: "WWWSHOWAIV",
  TINVOICES: "WWWSHOWTIV",
  EINVOICES: "WWWSHOWEIV",
  RINVOICES: "WWWSHOWRIV",
  ORDERS: "WWWSHOWORDER",
  CPROF: "WWWSHOWCPROF",
};

//* This functions finds invoice page type and its invoiceNo input title
export const findPriorityPageType = (title) => {
  for (const page of priorityPageTypes) {
    for (const titleObj of page.titles) {
      if (titleObj.pageTitle === title) {
        return { type: page.key, inputTitle: titleObj.inputTitle };
      }
    }
  }
  return null;
};
//* That function returns procedure name by invoice page title
export const getProcedureNameByTitle = (title) => {
  const invoicePageType = title && findPriorityPageType(title);
  return invoicePageType && procedureNameByType[invoicePageType.type]
    ? procedureNameByType[invoicePageType.type]
    : null;
};
