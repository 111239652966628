import React, { useEffect, useRef, useState } from "react";
import localizations from "../../localizations.json";
import { priorityPageTypes, procedureNameByType } from "./PriorityData";
import {
  UseFetchGet,
  UseFetchPost,
  UseFetchPostForm,
} from "../../hooks/fetchFunctions";
import { IsJsonString } from "../../libs/helpers";
import DropdownIndicator from "../../modules/DropdownIndicator";
import ReactSelect from "react-select";
import TemplatesMenu from "../Templates/TemplatesMenu";
import { whatsappChatSmallIcon } from "../../libs/images";
import { enqueueSnackbar } from "notistack";

function PrioritySendDocsChat({
  isPrioritySendDocsWindow,
  handlePrioritySendDocsWindow,
  handleSetNotif,
  globalID,
  businessInfo,
  templatesList,
  currentContact,
  fetchWhatsappTemplates,
  handleSendPriorityUploadState,
  priorityDocTypes,
}) {
  const [localization, setLocalization] = useState(
    localizations["he"].priorityDocsChat,
  );

  const sendDocTargetOptions = [
    {
      value: "invoiceContact",
      label: localization.docsList.target.contactTypeSelect.invoiceContact,
    },
    {
      value: "phoneBookCustomer",
      label: localization.docsList.target.contactTypeSelect.phoneBookCustomer,
    },
    {
      value: "phoneBookAll",
      label: localization.docsList.target.contactTypeSelect.phoneBookAll,
    },
    {
      value: "newContact",
      label: localization.docsList.target.contactTypeSelect.newContact,
    },
    {
      value: "test",
      label: localization.docsList.target.contactTypeSelect.test,
    },
  ];
  const statusFilterOptions = [
    {
      value: "final",
      label: localization.docsList.search.status.final,
    },
    {
      value: "notFinal",
      label: localization.docsList.search.status.notFinal,
    },
  ];

  const [windowStep, setWindowStep] = useState("selectDocType");
  const [loading, setLoading] = useState(false);
  const [documentMediaLoading, setDocumentMediaLoading] = useState(false);
  const [isShowDocPreview, setIsShowDocPreview] = useState(false);
  const [selectedDocType, setSelectedDocType] = useState(null);
  const [docsList, setDocsList] = useState([]);
  const [docsListReady, setDocsListReady] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [searchParams, setSearchParams] = useState({
    docId: "",
    name: "",
    from: "",
    to: "",
    filter: "final",
  });
  const templatesWithDocFile = templatesList.templates.filter(
    (template) =>
      template.components.find((comp) => comp.type === "HEADER")?.format ===
      "DOCUMENT",
  );
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const [docInfo, setDocInfo] = useState(null);
  const [selectedDocTypeObj, setSelectedDocTypeObj] = useState(null);
  const [selectedDocId, setSelectedDocId] = useState(null);
  const [sendDocOptions, setSendDocOptions] = useState({
    format: "normal",
    target: "invoiceContact",
    type: null,
  });
  const docFileStatusRef = useRef(false);
  const searchParamsRef = useRef({
    docId: "",
    name: "",
    from: "",
    to: "",
    filter: "final",
  });
  const selectedTemplateRef = useRef(null);
  const previewBtnRef = useRef(null);
  const templatesBtnRef = useRef(null);
  const defaultTemplateNameRef = useRef(null);

  const [procedureObject, setProcedureObject] = useState(null);
  const [sendDocFormatOptions, setSendDocFormatOptions] = useState([]);
  const [visiblePhoneBookContacts, setVisiblePhoneBookContacts] = useState({
    customerPhonebookContacts: [],
    allPhonebookContacts: [],
  });
  const [phoneBookSelectedContact, setPhoneBookSelectedContact] = useState({
    customer: null,
    all: null,
  });
  const [contactCreate, setContactCreate] = useState({
    name: "",
    phone: "",
  });
  const [testPhone, setTestPhone] = useState("");

  const defaultTemplate =
    IsJsonString(businessInfo.CompanyPlatforms) &&
    JSON.parse(businessInfo.CompanyPlatforms).priority?.configurations
      ?.defaultTemplate
      ? JSON.parse(businessInfo.CompanyPlatforms).priority?.configurations
          ?.defaultTemplate?.sendObject
      : null;
  // {
  //     to: "",
  //     templateName: "file_message",
  //     languageCode: "he",
  //     components: `{"BODY":{"text":"הי {{1}} בברכה צוות פאנר."},"HEADER":{"format":"DOCUMENT","text":""}}`,
  //     frontTemplateObj: `{"messaging_product":"whatsapp","to":"","type":"template","template":{"name":"file_message","language":{"code":"he"},"components":[{"type":"BODY","parameters":[{"type":"text","text":""}]},{"type":"HEADER","parameters":[{"type":"document","document":{"link":"", "filename":""}}]}]}}`,
  //     userglobalid: globalID,
  //     templateText: "",
  //   };

  const docInfoRef = useRef(null);
  const selectedDocIdRef = useRef(null);
  const phoneBookSelectedContactRef = useRef(null);
  const contactCreateRef = useRef(null);
  const testPhoneRef = useRef(null);
  const procedureObjectRef = useRef(null);
  const windowStepRef = useRef(null);

  const closePopup = () => {
    handlePrioritySendDocsWindow(false);
    setTimeout(() => {
      resetSelections();
    }, 500);
  };
  const closePopupBackground = (e) => {
    if (!e.target.closest(".priority-documents-popup__content")) {
      handlePrioritySendDocsWindow(false);
      setTimeout(() => {
        resetSelections();
      }, 500);
    }
  };
  const handleWindowStep = (step) => {
    setWindowStep(step);
    windowStepRef.current = step;
  };
  const handleSelectedDocType = (type) => {
    setSelectedDocType(type);
  };
  const handleSubmitDocType = () => {
    handleWindowStep("docsList");
    getPriorityDocObject(selectedDocType);
  };
  const handleInputValue = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };
  const handleSearchParam = (option, value) => {
    setSearchParams((prevObj) => {
      const newObj = { ...prevObj };
      newObj[option] = value;
      return newObj;
    });
    searchParamsRef.current[option] = value;
  };
  const closeTemplateChoose = () => {
    handleWindowStep("docsList");
  };

  const handleSendDocOptions = async (option, value) => {
    setSendDocOptions((prevObj) => {
      const newObj = { ...prevObj };
      newObj[option] = value;
      return newObj;
    });
  };

  const resetSelections = () => {
    setLoading(false);
    setDocumentMediaLoading(false);
    handleWindowStep("selectDocType");
    setSelectedDocType(null);
    setDocsList([]);
    setDocsListReady(false);
    setIsShowDocPreview(false);
    setSelectedDocTypeObj(null);

    setSearchParams({
      docId: "",
      name: "",
      from: "",
      to: "",
      filter: "final",
    });
    searchParamsRef.current = {
      docId: "",
      name: "",
      from: "",
      to: "",
      filter: "final",
    };

    setDocInfo(null);
    setSelectedDocId(null);
    selectedDocIdRef.current = null;
    docFileStatusRef.current = false;

    setProcedureObject(null);
    procedureObjectRef.current = null;
    setSendDocFormatOptions([]);
    setVisiblePhoneBookContacts({
      customerPhonebookContacts: [],
      allPhonebookContacts: [],
    });
    setPhoneBookSelectedContact({
      customer: null,
      all: null,
    });
    setSendDocOptions({
      format: "normal",
      target: "invoiceContact",
      type: null,
    });
    setContactCreate({
      name: "",
      phone: "",
    });
    setTestPhone("");
  };
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(2);

    return `${day}.${month}.${year}`;
  }

  const handleSelectedDocAction = async (action, doc) => {
    // if (doc.FINAL !== "Y")
    //   return handleSetNotif(localization.docsList.docNotReady, "error");

    if (action === "sendMessage") {
      closePopup();
      sendTemplateMsgWithDoc(currentContact?.UserDisplayPhoneNumber, doc);
    } else if (action === "openTemplatesChoose") {
      handleWindowStep("templateChoose");
      if (selectedDocIdRef.current !== doc[procedureObject.idIdentifier]) {
        getDocumentInfo(
          doc[procedureObject.idIdentifier],
          procedureObject?.procedureName,
        );
      }
    } else if (action === "sendRegularMessage") {
      if (selectedDocIdRef.current !== doc[procedureObject.idIdentifier]) {
        setLoading(true);
        await getDocumentInfo(
          doc[procedureObject.idIdentifier],
          procedureObject?.procedureName,
        );
        setLoading(false);
      }
      handleSendPriorityUploadState({
        isUpload: true,
        file: {
          name: `${doc[procedureObject.idIdentifier]}.pdf`,
          link: docInfoRef.current.previewImageLink.pdfLinkString,
        },
      });

      closePopup();
    }
  };

  async function getPriorityDocObject(selectedDocType) {
    console.log(selectedDocType);
    const docType = priorityDocTypes.find(
      (item) => item.docIndetificator === selectedDocType,
    );
    setProcedureObject({ ...docType });
    procedureObjectRef.current = { ...docType };
    // try {
    //   setLoading(true);

    //   const res = await UseFetchGet(
    //     `/api/priority/getPriorityDocObject`,
    //     {
    //       procedureName: procedureName,
    //     },
    //     null,
    //     undefined,
    //     "priority",
    //   );

    //   if (res?.data) {
    //     setProcedureObject(res.data.procedureObject);
    //     procedureObjectRef.current = res.data.procedureObject;
    //     defaultTemplateNameRef.current = res.data.defaultTemplate;
    //   }
    //   setLoading(false);
    // } catch (error) {
    //   console.log("getPriorityDocObject error: ", error);
    //   setLoading(false);
    // }
  }

  const onSearchListEnterPress = (e) => {
    if (windowStepRef.current !== "docsList") return;

    if (e.keyCode === 13 || e.code === 13) {
      getPriorityDocsList(procedureNameByType[selectedDocType]);
    }
  };
  async function getPriorityDocsList(procedureName) {
    try {
      setLoading(true);
      setDocsList([]);
      // setProcedureObject(null);
      // procedureObjectRef.current = null;
      setSendDocFormatOptions([]);
      setDocsListReady(false);

      const res = await UseFetchGet(
        "/api/priority/getPriorityDocsList",
        {
          procedureName: procedureName,
          docType: procedureObjectRef.current.docIndetificator,
          from: searchParamsRef.current.from.replaceAll(".", "-"),
          to: searchParamsRef.current.to.replaceAll(".", "-"),
          name: searchParamsRef.current.name,
          docId: searchParamsRef.current.docId,
          filter: searchParamsRef.current.filter,
        },
        null,
        undefined,
        "priority",
      );

      if (res?.data) {
        const parsedDocs = IsJsonString(res.data.docs)
          ? JSON.parse(res.data.docs)
          : res.data.docs;
        const newDocsList =
          parsedDocs?.value?.length > 0 ? parsedDocs.value : [];

        setDocsList(newDocsList);
        // setProcedureObject(res.data.procedureObject);
        const formatOptions = res.data.procedureObject
          ? res.data.procedureObject.formats.map((format) => {
              return {
                ...format,
                label: format.title,
                value: format.format,
              };
            })
          : [];
        setSendDocFormatOptions(formatOptions);
        handleSendDocOptions(
          "format",
          formatOptions.length ? formatOptions[0].value : null,
        );
        setDocsListReady(true);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  // async function getDocumentInfo(docId, procedureName) {
  async function getDocumentInfo(docId) {
    try {
      setSelectedDocId(docId);
      selectedDocIdRef.current = docId;
      setDocInfo({});
      docInfoRef.current = {};

      // setLoading(true);
      await getDocumentFile();
    } catch (error) {
      setLoading(false);
      setDocInfo(false);
    }
  }
  async function getDocumentFile(format = null) {
    try {
      setDocumentMediaLoading(true);
      const docInfoRes = await UseFetchGet(
        `/api/priority/getPriorityDoc`,
        {
          docId: selectedDocIdRef.current,
          format: format ? format : sendDocOptions.format,
          // procedureName: procedureObject.procedureName,
          docType: procedureObjectRef.current.docIndetificator,
        },
        null,
        undefined,
        "priority",
      );

      if (docInfoRes?.status === 200) {
        console.log(docInfo);
        console.log(docInfoRef);
        setDocInfo((prevObj) => {
          return {
            ...prevObj,
            previewImageLink: docInfoRes.data.previewImageLink,
          };
        });
        if (docInfoRef.current) {
          docInfoRef.current.previewImageLink =
            docInfoRes.data.previewImageLink;
        } else {
          docInfoRef.current = {
            previewImageLink: docInfoRes.data.previewImageLink,
          };
        }

        docFileStatusRef.current = true;
        return true;
      } else {
        handleSetNotif(
          localizations["he"].priorityDocsChat.notifications.documentLoadError,
          "error",
        );
        docFileStatusRef.current = false;
        return false;
      }
    } catch (error) {
      console.log(error);
      handleSetNotif(
        localizations["he"].priorityDocsChat.notifications.documentLoadError,
        "error",
      );
      docFileStatusRef.current = false;
      return false;
    } finally {
      setDocumentMediaLoading(false);
    }
  }

  const handleSendInChooseTemplate = async () => {
    closePopup();
    const result = await sendSelectedTemplateMsgWithDoc(
      currentContact?.UserDisplayPhoneNumber,
    );
    // if (result) {
    //   resetSelections();
    // } else {
    //   handlePrioritySendDocsWindow(true);
    // }
  };

  async function sendTemplateMsgWithDoc(phone = null, doc) {
    if (!defaultTemplate)
      return enqueueSnackbar(localization.notifications.noDefaultTemplate, {
        variant: "error",
      });
    let actionResult;
    let params;
    // const currentDocId = selectedDocIdRef.current;
    const currentDocId = doc[procedureObject.idIdentifier];

    handleSetNotif(
      <div style={{ textAlign: "right" }}>
        <div style={{ fontSize: "1.15em" }}>
          {localization.notifications.warning.title}
        </div>
        <div>
          {localization.notifications.warning.docIdText} {currentDocId}
        </div>
        <div>
          {localization.notifications.warning.templateNameText} file_message
        </div>
        <div>
          {localization.notifications.warning.phoneNumText}{" "}
          {phone ? phone : docInfoRef.current.customerPhone}
        </div>
      </div>,
      "info",
      false,
    );
    if (selectedDocIdRef.current !== doc[procedureObject.idIdentifier]) {
      await getDocumentInfo(
        doc[procedureObject.idIdentifier],
        procedureObject?.procedureName,
      );
    }

    if (!docFileStatusRef.current) {
      handleSetNotif(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.error.title.beforeText}{" "}
            {procedureObject?.title}{" "}
            {localization.notifications.error.title.afterText}
          </div>
          <div>
            {localization.notifications.error.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.error.phoneNumText}{" "}
            {phone
              ? phone
              : `0
            ${params.to.slice(3)}`}
          </div>
          <div>{localization.notifications.error.errorReason.sendError}</div>
        </div>,
        "error",
        false,
      );
      return false;
    }
    try {
      if (phone) {
        // params = {
        //   to: phone,
        //   templateName: "file_message",
        //   languageCode: "he",
        //   components: `{"BODY":{"text":"הי ${currentContact.FullName} בברכה צוות פאנר."},"HEADER":{"format":"DOCUMENT","text":"${docInfoRef.current.previewImageLink.pdfLinkString}"}}`,
        //   frontTemplateObj: `{"messaging_product":"whatsapp","to":"${phone}","type":"template","template":{"name":"file_message","language":{"code":"he"},"components":[{"type":"BODY","parameters":[{"type":"text","text":"${currentContact.FullName}"}]},{"type":"HEADER","parameters":[{"type":"document","document":{"link":"${docInfoRef.current.previewImageLink.pdfLinkString}", "filename":"${currentDocId}.pdf"}}]}]}}`,
        //   userglobalid: globalID,
        //   templateText: "",
        // };
        const components = JSON.parse(defaultTemplate.components);
        if (defaultTemplate.templateName === "file_message") {
          components.BODY.text = components.BODY.text.replace(
            "{{1}}",
            currentContact.FullName,
          );
        }
        components.HEADER.text =
          docInfoRef.current.previewImageLink.pdfLinkString;

        const frontTemplateObj = JSON.parse(defaultTemplate.frontTemplateObj);
        frontTemplateObj.to = phone;
        const headerComp = frontTemplateObj.template.components.find(
          (comp) => comp.type === "HEADER",
        );
        headerComp.parameters[0].document = {
          link: docInfoRef.current.previewImageLink.pdfLinkString,
          filename: `${currentDocId}.pdf`,
        };
        if (defaultTemplate.templateName === "file_message") {
          const bodyComp = frontTemplateObj.template.components.find(
            (comp) => comp.type === "BODY",
          );
          bodyComp.parameters[0].text = currentContact.FullName;
          console.log(bodyComp);
        }

        params = {
          ...defaultTemplate,
          to: phone,
          components: JSON.stringify(components),
          frontTemplateObj: JSON.stringify(frontTemplateObj),
          userglobalid: globalID,
        };
      }

      const noWhatsappErr = "No phone number WhatsApp account";
      setLoading(true);

      const response = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        params,
        "messages",
      );
      if (response.data.result) {
        handleSetNotif(
          <div style={{ textAlign: "right" }}>
            <div style={{ fontSize: "1.15em" }}>
              {procedureObject?.title} {currentDocId}{" "}
              {localization.notifications.success.title}
            </div>
            <div>
              {localization.notifications.success.docIdText} {currentDocId}
            </div>
            <div>
              {localization.notifications.success.templateNameText}{" "}
              {params.templateName}
            </div>
            <div>
              {localization.notifications.success.phoneNumText} 0
              {params.to.slice(3)}
            </div>
          </div>,
          "success",
          false,
        );
        actionResult = true;
        // throw new Error("Error: " + response.data.message);
      } else {
        if (response.data.message === noWhatsappErr) {
          handleSetNotif(
            <div style={{ textAlign: "right" }}>
              <div style={{ fontSize: "1.15em" }}>
                {localization.notifications.error.title.beforeText}{" "}
                {procedureObject?.title}{" "}
                {localization.notifications.error.title.afterText}
              </div>
              <div>
                {localization.notifications.error.docIdText} {currentDocId}
              </div>
              <div>
                {localization.notifications.error.phoneNumText} 0
                {params.to.slice(3)}
              </div>
              <div>
                {localization.notifications.error.errorReason.noWhatsappNumber}
              </div>
            </div>,
            "error",
            false,
          );
        } else
          handleSetNotif(
            <div style={{ textAlign: "right" }}>
              <div style={{ fontSize: "1.15em" }}>
                {localization.notifications.error.title.beforeText}{" "}
                {procedureObject?.title}{" "}
                {localization.notifications.error.title.afterText}
              </div>
              <div>
                {localization.notifications.error.docIdText} {currentDocId}
              </div>
              <div>
                {localization.notifications.error.phoneNumText} 0
                {params.to.slice(3)}
              </div>
              <div>{response.data.message}</div>
            </div>,
            "error",
            false,
          );
        actionResult = false;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      actionResult = false;
      handleSetNotif(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.error.title.beforeText}{" "}
            {procedureObject?.title}{" "}
            {localization.notifications.error.title.afterText}
          </div>
          <div>
            {localization.notifications.error.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.error.phoneNumText} 0
            {params.to.slice(3)}
          </div>
          <div>{localization.notifications.error.errorReason.sendError}</div>
        </div>,
        "error",
        false,
      );
      //console.log(error);
    }
    return actionResult;
  }
  async function sendSelectedTemplateMsgWithDoc(phone = null) {
    let actionResult;
    let params = { ...selectedTemplateRef.current };
    let currentDocId = selectedDocIdRef.current;

    handleSetNotif(
      <div style={{ textAlign: "right" }}>
        <div style={{ fontSize: "1.15em" }}>
          {localization.notifications.warning.title}
        </div>
        <div>
          {localization.notifications.warning.docIdText} {currentDocId}
        </div>
        <div>
          {localization.notifications.warning.templateNameText}{" "}
          {params.templateName}
        </div>
        <div>
          {localization.notifications.warning.phoneNumText}{" "}
          {phone ? phone : docInfoRef.current.customerPhone}
        </div>
      </div>,
      "info",
      false,
    );

    // const getDocFileRes = await getDocumentFile();
    if (!docFileStatusRef.current) {
      handleSetNotif(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.error.title.beforeText}{" "}
            {procedureObject?.title}{" "}
            {localization.notifications.error.title.afterText}
          </div>
          <div>
            {localization.notifications.error.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.error.phoneNumText} 0
            {params.to.slice(3)}
          </div>
          <div>{localization.notifications.error.errorReason.sendError}</div>
        </div>,
        "error",
        false,
      );
      return false;
    }

    try {
      if (phone) {
        params.to = phone;
        const parsedFrontObj = JSON.parse(params.frontTemplateObj);
        parsedFrontObj.to = phone;
        params.frontTemplateObj = JSON.stringify(parsedFrontObj);
      }

      const noWhatsappErr = "No phone number WhatsApp account";
      // setLoading(true);

      const response = await UseFetchPost(
        "/api/whatsapp/SendWhatsappTemplateMessage",
        params,
        "messages",
      );
      if (response.data.result) {
        handleSetNotif(
          <div style={{ textAlign: "right" }}>
            <div style={{ fontSize: "1.15em" }}>
              {procedureObject?.title} {currentDocId}{" "}
              {localization.notifications.success.title}
            </div>
            <div>
              {localization.notifications.success.docIdText} {currentDocId}
            </div>
            <div>
              {localization.notifications.success.templateNameText}{" "}
              {params.templateName}
            </div>
            <div>
              {localization.notifications.success.phoneNumText} 0
              {params.to.slice(3)}
            </div>
          </div>,
          "success",
          false,
        );
        actionResult = true;
        // throw new Error("Error: " + response.data.message);
      } else {
        if (response.data.message === noWhatsappErr) {
          handleSetNotif(
            <div style={{ textAlign: "right" }}>
              <div style={{ fontSize: "1.15em" }}>
                {localization.notifications.error.title.beforeText}{" "}
                {procedureObject?.title}{" "}
                {localization.notifications.error.title.afterText}
              </div>
              <div>
                {localization.notifications.error.docIdText} {currentDocId}
              </div>
              <div>
                {localization.notifications.error.phoneNumText} 0
                {params.to.slice(3)}
              </div>
              <div>
                {localization.notifications.error.errorReason.noWhatsappNumber}
              </div>
            </div>,
            "error",
            false,
          );
        } else
          handleSetNotif(
            <div style={{ textAlign: "right" }}>
              <div style={{ fontSize: "1.15em" }}>
                {localization.notifications.error.title.beforeText}{" "}
                {procedureObject?.title}{" "}
                {localization.notifications.error.title.afterText}
              </div>
              <div>
                {localization.notifications.error.docIdText} {currentDocId}
              </div>
              <div>
                {localization.notifications.error.phoneNumText} 0
                {params.to.slice(3)}
              </div>
              <div>{response.data.message}</div>
            </div>,
            "error",
            false,
          );
        actionResult = false;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      actionResult = false;
      handleSetNotif(
        <div style={{ textAlign: "right" }}>
          <div style={{ fontSize: "1.15em" }}>
            {localization.notifications.error.title.beforeText}{" "}
            {procedureObject?.title}{" "}
            {localization.notifications.error.title.afterText}
          </div>
          <div>
            {localization.notifications.error.docIdText} {currentDocId}
          </div>
          <div>
            {localization.notifications.error.phoneNumText} 0
            {params.to.slice(3)}
          </div>
          <div>{localization.notifications.error.errorReason.sendError}</div>
        </div>,
        "error",
        false,
      );
      //console.log(error);
    }
    return actionResult;
  }

  useEffect(() => {
    document.addEventListener("keydown", onSearchListEnterPress);

    return () =>
      document.removeEventListener("keydown", onSearchListEnterPress);
  }, []);

  useEffect(() => {
    console.log("docInfo: ", docInfo);
    docInfoRef.current = docInfo;
    phoneBookSelectedContactRef.current = phoneBookSelectedContact;
    contactCreateRef.current = contactCreate;
    testPhoneRef.current = testPhone;
  }, [docInfo, phoneBookSelectedContact, contactCreate, testPhone]);

  // ===============

  return (
    <div
      className={`priority-documents-popup priority-send-docs priority-send-docs--web priority-documents-popup--anim root-whatsapp ${
        isPrioritySendDocsWindow ? "visible" : ""
      }`}
      onClick={closePopupBackground}
    >
      <div className="priority-documents-popup__wrapper">
        <div
          className="priority-documents-popup__content"
          style={windowStep === "docsList" ? { maxWidth: 1200 } : null}
        >
          <div
            className="priority-documents-popup__close funner-icon"
            onClick={closePopup}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" />
            </svg>
          </div>
          {windowStep === "selectDocType" && (
            <>
              <div className="priority-documents-popup__header">
                <div
                  className="funner-icon"
                  style={{ width: 30, height: 30, flex: "0 0 30px" }}
                >
                  <img
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAYhJREFUSA3tlDtLA1EQhXdF0cJHZRNLK0URIvZBsbA2XWztLOwt8xtstfEPiL3a2SkBwULFShu7BASr9TvhTri5+8gVwSoDJzN3cuZ1d3eSZCwjbiAd8X+SZdkcnDUwXcKt4X8Dj2ma9ko4xW6SN0EPxIh4zTBT6QSQ1fkHeAJtUNTdPv4jcAbWwQqo+ZNM4igTXcssaBNwVUSiiQ3n/0KLswUUdwf6UlXA7lyj12HPuxhTXQwllmgKk28zpKsKGG8J48YOgT5w52N0B3SZ9t7nxBR4J6AFFv1A7E+gZyTpkPi2bwU/MQU0wUUQZ0ebwM45HVMgZoJcYnPEFPiXCTbpqOgtCn3W+EBPDCzPcB/ZqnPpXfcTTXHWVPoAfy8kj10PL3D3gKRRVmnoGUDUejgHth7UuR6yiaY6BddgG+jqKmWoAMzK9UADluwVQwV2nGPo6zWSdFjA1sNyydiW8NAlaaCfwYM7VyuS1kGMnEDaBS0wU5U1t64JKFpsfo4F1sKl7xjbf7qBH7S/56VIJxNkAAAAAElFTkSuQmCC"
                    alt=""
                  />
                </div>
                {localization.selectDocType.header.title}
              </div>
              <div className="priority-documents-popup__body">
                <div
                  className="priority-documents-popup__buttons-row"
                  style={{ marginBottom: 10 }}
                >
                  {priorityPageTypes.map((type) => (
                    <div
                      className="priority-documents-popup__btn-wrapper"
                      key={type.key}
                    >
                      <label
                        className={`priority-documents-popup__button ${
                          selectedDocType === type.key ? "_checked" : ""
                        }`}
                      >
                        {selectedDocType === type.key && (
                          <svg
                            width="64px"
                            height="64px"
                            viewBox="0 0 48 48"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="#6FC5BB"
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              width: 16,
                              height: 16,
                            }}
                          >
                            <g id="SVGRepo_bgCarrier" strokeWidth={0} />
                            <g
                              id="SVGRepo_tracerCarrier"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <g id="SVGRepo_iconCarrier">
                              {"{"}" "{"}"}
                              <title>check-circle-solid</title>
                              {"{"}" "{"}"}
                              <g id="Layer_2" data-name="Layer 2">
                                {"{"}" "{"}"}
                                <g id="invisible_box" data-name="invisible box">
                                  {"{"}" "{"}"}
                                  <rect width={48} height={48} fill="none" />
                                  {"{"}" "{"}"}
                                </g>
                                {"{"}" "{"}"}
                                <g id="icons_Q2" data-name="icons Q2">
                                  {"{"}" "{"}"}
                                  <path d="M24,2A22,22,0,1,0,46,24,21.9,21.9,0,0,0,24,2ZM35.4,18.4l-14,14a1.9,1.9,0,0,1-2.8,0l-5.9-5.9a2.2,2.2,0,0,1-.4-2.7,2,2,0,0,1,3.1-.2L20,28.2,32.6,15.6a2,2,0,0,1,2.8,2.8Z" />
                                  {"{"}" "{"}"}
                                </g>
                                {"{"}" "{"}"}
                              </g>
                              {"{"}" "{"}"}
                            </g>
                          </svg>
                        )}
                        <input
                          type="radio"
                          name="priority-doc-type"
                          value={type.key}
                          checked={selectedDocType === type.key}
                          onChange={(e) =>
                            handleSelectedDocType(type.key, e.target.checked)
                          }
                        />
                        <div className="priority-documents-popup__button-title">
                          {/* {localization.selectDocType.typesList.ainvoices.title} */}
                          {type.sendChatShortName}
                        </div>
                        <div
                          className="priority-documents-popup__button-text"
                          style={{ textAlign: "center" }}
                        >
                          {/* {localization.selectDocType.typesList.ainvoices.text} */}
                          {type.sendChatTitle}
                        </div>
                      </label>
                    </div>
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 15,
                    justifyContent: "flex-end",
                  }}
                >
                  <button
                    className="funner-priority-btn funner-priority-btn--outline"
                    onClick={closePopup}
                  >
                    {localization.selectDocType.buttons.close}
                  </button>
                  <button
                    className="funner-priority-btn funner-priority-btn--fill"
                    onClick={() => handleSubmitDocType()}
                    disabled={!selectedDocType}
                  >
                    {localization.selectDocType.buttons.action}
                  </button>
                </div>
              </div>
            </>
          )}
          {windowStep === "docsList" && (
            <>
              <div className="popovers__header">
                <h3 className="popovers__title">
                  <span
                    className="funner-icon"
                    style={{ width: 24, height: 24, flex: "0 0 24px" }}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM20 16H8V4H20V16ZM4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM16 12V9C16 8.45 15.55 8 15 8H13V13H15C15.55 13 16 12.55 16 12ZM14 9H15V12H14V9ZM18 11H19V10H18V9H19V8H17V13H18V11ZM10 11H11C11.55 11 12 10.55 12 10V9C12 8.45 11.55 8 11 8H9V13H10V11ZM10 9H11V10H10V9Z"
                        fill="black"
                        fillOpacity="0.54"
                      />
                    </svg>
                  </span>
                  {localization.docsList.header.titleBefore}{" "}
                  {procedureObject?.title}{" "}
                  {localization.docsList.header.titleAfter}
                </h3>
              </div>
              <div className="popovers__content">
                {loading && (
                  <div
                    className="whatsapp-loader loader active"
                    style={{ marginBottom: 20 }}
                  >
                    <div className="loader__element"></div>
                  </div>
                )}
                <div
                  className="popovers__content-container doc-popover"
                  style={{ flexDirection: "row", gap: 18 }}
                >
                  <div
                    className="doc-popover__text-col popover__text-col"
                    style={{ width: "100%", flex: "0 0 100%" }}
                  >
                    <div className="priority-send-docs__title">
                      {localization.docsList.search.title}
                    </div>
                    <div
                      className="priority-send-docs__search-wrapper"
                      style={{
                        paddingBottom: 18,
                        borderBottom: "1px solid #0000001F",
                        marginBottom: 18,
                      }}
                    >
                      <div className="priority-send-docs__search">
                        <div className="priority-send-docs__search-item">
                          <div style={{ position: "relative" }}>
                            <span className="input-title">
                              {localization.docsList.search.docIdTitle}
                            </span>
                            <input
                              type="text"
                              value={searchParams.docId}
                              className="form-get-token-popup__input"
                              onChange={(e) =>
                                handleSearchParam("docId", e.target.value)
                              }
                              style={{ height: 34 }}
                            />
                          </div>
                        </div>
                        <div className="priority-send-docs__search-item">
                          <div style={{ position: "relative" }}>
                            <span className="input-title">
                              {localization.docsList.search.customerNameTitle}
                            </span>
                            <input
                              type="text"
                              value={searchParams.name}
                              className="form-get-token-popup__input"
                              onChange={(e) =>
                                handleSearchParam("name", e.target.value)
                              }
                              style={{ height: 34 }}
                            />
                          </div>
                        </div>
                        <div className="priority-send-docs__search-item">
                          <div style={{ position: "relative" }}>
                            <span
                              className="input-title"
                              style={{ zIndex: 11 }}
                            >
                              {localization.docsList.search.status.title}
                            </span>
                            <ReactSelect
                              options={statusFilterOptions}
                              isClearable={false}
                              // isRtl={true}
                              // isDisabled={documentMediaLoading}
                              // hideSelectedOptions={true}
                              // placeholder={
                              //   localization.docsList.format.placeholder
                              // }
                              value={
                                statusFilterOptions.some(
                                  (option) =>
                                    option.value === searchParams.filter,
                                )
                                  ? statusFilterOptions.find(
                                      (option) =>
                                        option.value === searchParams.filter,
                                    )
                                  : null
                              }
                              onChange={(e) =>
                                handleSearchParam("filter", e.value)
                              }
                              // theme={}
                              components={{
                                DropdownIndicator,
                                IndicatorSeparator: () => null,
                              }}
                              styles={{
                                container: (provided) => ({
                                  ...provided,
                                  zIndex: 10,
                                  height: 34,
                                  minHeight: 34,
                                }),
                                valueContainer: (provided) => ({
                                  ...provided,
                                  height: 34,
                                  minHeight: 34,
                                }),
                                control: (provided, state) => ({
                                  ...provided,
                                  borderColor: "#0000003B",
                                  borderRadius: "4px",
                                  color: "#000000DE",
                                  fontSize: "14px",
                                  lineHeight: "18px",
                                  letterSpacing: "0.15px",
                                  cursor: "pointer",
                                  height: 34,
                                  minHeight: 34,
                                }),
                                singleValue: (provided) => ({
                                  ...provided,
                                  fontWeight: 500,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }),
                                option: (provided, state) => ({
                                  ...provided,
                                  cursor: "pointer",
                                  fontWeight: 500,
                                  color: "rgba(0, 0, 0, 0.87)",
                                }),
                              }}
                              theme={(theme) => ({
                                ...theme,
                                borderRadius: "4px",
                                colors: {
                                  ...theme.colors,
                                  primary25: "rgba(60, 174, 241, 0.25)",
                                  primary50: "rgba(60, 174, 241, 0.5)",
                                  primary75: "rgba(60, 174, 241, 0.75)",
                                  primary: "rgb(60, 174, 241)",
                                },
                              })}
                            />
                          </div>
                        </div>
                        <div className="priority-send-docs__search-item">
                          <div style={{ position: "relative" }}>
                            <span className="input-title">
                              {localization.docsList.search.dateFromTitle}
                            </span>
                            <input
                              type="date"
                              value={searchParams.from}
                              className="form-get-token-popup__input"
                              onChange={(e) =>
                                handleSearchParam("from", e.target.value)
                              }
                              style={{ height: 34 }}
                            />
                          </div>
                        </div>
                        <div className="priority-send-docs__search-item">
                          <div style={{ position: "relative" }}>
                            <span className="input-title">
                              {localization.docsList.search.dateToTitle}
                            </span>
                            <input
                              type="date"
                              value={searchParams.to}
                              className="form-get-token-popup__input"
                              onChange={(e) =>
                                handleSearchParam("to", e.target.value)
                              }
                              style={{ height: 34 }}
                            />
                          </div>
                        </div>
                        {/* <div
                          className="priority-send-docs__search-item"
                        > */}
                        <button
                          className="funner-priority-btn funner-priority-btn--fill"
                          onClick={() =>
                            // getPriorityDocsList(procedureObject.procedureName)
                            getPriorityDocsList(
                              procedureNameByType[selectedDocType],
                            )
                          }
                          // disabled={!selectedDocType}
                          style={{ height: 34 }}
                        >
                          {localization.docsList.search.submitBtnText}
                        </button>
                        {/* </div> */}
                      </div>
                    </div>
                    {sendDocFormatOptions.length > 0 && (
                      <div
                        className="priority-documents-popup__format"
                        style={{ marginBottom: 18 }}
                      >
                        <div className="priority-send-docs__title">
                          {localization.docsList.format.title}
                        </div>
                        <ReactSelect
                          options={sendDocFormatOptions}
                          isClearable={false}
                          // isRtl={true}
                          isDisabled={documentMediaLoading}
                          // hideSelectedOptions={true}
                          placeholder={localization.docsList.format.placeholder}
                          value={
                            sendDocFormatOptions.some(
                              (option) =>
                                option.value === sendDocOptions.format,
                            )
                              ? sendDocFormatOptions.find(
                                  (option) =>
                                    option.value === sendDocOptions.format,
                                )
                              : null
                          }
                          onChange={(e) =>
                            handleSendDocOptions("format", e.value)
                          }
                          // theme={}
                          components={{
                            DropdownIndicator,
                            IndicatorSeparator: () => null,
                          }}
                          styles={{
                            container: (provided) => ({
                              ...provided,
                              zIndex: 10,
                            }),
                            control: (provided, state) => ({
                              ...provided,
                              borderColor: "#0000003B",
                              borderRadius: "4px",
                              color: "#000000DE",
                              fontSize: "14px",
                              lineHeight: "18px",
                              letterSpacing: "0.15px",
                              cursor: "pointer",
                            }),
                            singleValue: (provided) => ({
                              ...provided,
                              fontWeight: 500,
                              color: "rgba(0, 0, 0, 0.87)",
                            }),
                            option: (provided, state) => ({
                              ...provided,
                              cursor: "pointer",
                              fontWeight: 500,
                              color: "rgba(0, 0, 0, 0.87)",
                            }),
                          }}
                          theme={(theme) => ({
                            ...theme,
                            borderRadius: "4px",
                            colors: {
                              ...theme.colors,
                              primary25: "rgba(60, 174, 241, 0.25)",
                              primary50: "rgba(60, 174, 241, 0.5)",
                              primary75: "rgba(60, 174, 241, 0.75)",
                              primary: "rgb(60, 174, 241)",
                            },
                          })}
                        />
                      </div>
                    )}

                    <div className={`doc-popover__content-wrapper`}>
                      <div className="priority-send-docs__title">
                        {localization.docsList.table.title}
                      </div>
                      <div
                        className="doc-popover__content-table-wrapper"
                        style={{ position: "relative" }}
                      >
                        <table className="default-funner-table">
                          <thead className="default-funner-table__header">
                            <tr className="default-funner-table__row">
                              <th className="default-funner-table__item">
                                {localization.docsList.table.header.docIdCol}
                              </th>
                              <th className="default-funner-table__item">
                                {
                                  localization.docsList.table.header
                                    .customerNameCol
                                }
                              </th>
                              <th className="default-funner-table__item">
                                {localization.docsList.table.header.dateCol}
                              </th>
                              <th className="default-funner-table__item">
                                {localization.docsList.table.header.statusCol}
                              </th>
                              <th className="default-funner-table__item">
                                {localization.docsList.table.header.priceCol}
                              </th>
                              <th className="default-funner-table__item">
                                {localization.docsList.table.header.buttonsCol}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="default-funner-table__body">
                            {docsList?.length > 0 &&
                              docsList.map((doc, index) => (
                                <tr
                                  key={doc[procedureObject.idIdentifier]}
                                  className="default-funner-table__row"
                                  style={{
                                    cursor: "pointer",
                                    backgroundColor:
                                      selectedDocId ===
                                      doc[procedureObject.idIdentifier]
                                        ? "rgba(25, 118, 210, 0.08)"
                                        : null,
                                  }}
                                >
                                  <td
                                    className="default-funner-table__item"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      gap: 8,
                                    }}
                                  >
                                    <span
                                      style={{
                                        width: 6,
                                        height: 6,
                                        flex: "0 0 6px",
                                        borderRadius: "50%",
                                        display: "inline-block",
                                        // backgroundColor:
                                        //   doc.FINAL === "Y"
                                        //     ? "#4caf50"
                                        //     : "#d32f2f",
                                        backgroundColor: "#4caf50",
                                      }}
                                    ></span>
                                    {doc[procedureObject.idIdentifier]}
                                  </td>
                                  <td className="default-funner-table__item">
                                    {doc.CUSTNAME}
                                  </td>
                                  <td className="default-funner-table__item">
                                    {formatDate(doc.IVDATE)}
                                  </td>
                                  <td className="default-funner-table__item">
                                    {doc.STATDES}
                                  </td>
                                  <td className="default-funner-table__item">
                                    ₪{doc.TOTPRICE}
                                  </td>
                                  <td className="default-funner-table__item">
                                    <div style={{ display: "flex", gap: 24 }}>
                                      {/* <div
                                        className="funner-icon priority-send-docs__preview"
                                        style={{
                                          width: 24,
                                          height: 24,
                                          flex: "0 0 24px",
                                        }}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M19 3H5C3.89 3 3 3.9 3 5V19C3 20.1 3.89 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.11 3 19 3ZM19 19H5V7H19V19ZM12 10.5C13.84 10.5 15.48 11.46 16.34 13C15.48 14.54 13.84 15.5 12 15.5C10.16 15.5 8.52 14.54 7.66 13C8.52 11.46 10.16 10.5 12 10.5ZM12 9C9.27 9 6.94 10.66 6 13C6.94 15.34 9.27 17 12 17C14.73 17 17.06 15.34 18 13C17.06 10.66 14.73 9 12 9ZM12 14.5C11.17 14.5 10.5 13.83 10.5 13C10.5 12.17 11.17 11.5 12 11.5C12.83 11.5 13.5 12.17 13.5 13C13.5 13.83 12.83 14.5 12 14.5Z"
                                            fill="black"
                                            fillOpacity="0.54"
                                          />
                                        </svg>
                                      </div> */}
                                      <div
                                        className="funner-icon priority-send-docs__templates"
                                        style={{
                                          width: 24,
                                          height: 24,
                                          flex: "0 0 24px",
                                        }}
                                        onClick={() =>
                                          handleSelectedDocAction(
                                            "openTemplatesChoose",
                                            doc,
                                          )
                                        }
                                      >
                                        <svg
                                          width="28"
                                          height="28"
                                          viewBox="0 0 28 28"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M9.83329 6.49992H7.33329V8.99992H5.66663V4.83325H9.83329V6.49992ZM22.3333 8.99992V4.83325H18.1666V6.49992H20.6666V8.99992H22.3333ZM9.83329 21.4999H7.33329V18.9999H5.66663V23.1666H9.83329V21.4999ZM20.6666 18.9999V21.4999H18.1666V23.1666H22.3333V18.9999H20.6666ZM18.1666 8.99992H9.83329V18.9999H18.1666V8.99992ZM19.8333 18.9999C19.8333 19.9166 19.0833 20.6666 18.1666 20.6666H9.83329C8.91663 20.6666 8.16663 19.9166 8.16663 18.9999V8.99992C8.16663 8.08325 8.91663 7.33325 9.83329 7.33325H18.1666C19.0833 7.33325 19.8333 8.08325 19.8333 8.99992V18.9999ZM16.5 10.6666H11.5V12.3333H16.5V10.6666ZM16.5 13.1666H11.5V14.8333H16.5V13.1666ZM16.5 15.6666H11.5V17.3333H16.5V15.6666Z"
                                            fill="black"
                                            fillOpacity="0.54"
                                          />
                                        </svg>
                                      </div>
                                      <div
                                        className="funner-icon priority-send-docs__send-message"
                                        style={{
                                          width: 24,
                                          height: 24,
                                          flex: "0 0 24px",
                                        }}
                                        onClick={() =>
                                          handleSelectedDocAction(
                                            "sendMessage",
                                            doc,
                                          )
                                        }
                                      >
                                        <svg
                                          width="22"
                                          height="22"
                                          viewBox="0 0 22 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M18.3241 16.4725L11.44 13.5208L18.3333 14.4375L18.3241 16.4725ZM11.4491 8.47917L18.3333 5.5275V7.5625L11.4491 8.47917ZM20.1575 19.25L20.1666 12.8333L6.41663 11L20.1666 9.16667L20.1575 2.75L0.916626 11L20.1575 19.25Z"
                                            fill="#1976D2"
                                          />
                                        </svg>
                                      </div>
                                      {currentContact.isRestricted24 && (
                                        <div
                                          className="funner-icon priority-send-docs__send-regular-msg"
                                          style={{
                                            width: 24,
                                            height: 24,
                                            flex: "0 0 24px",
                                          }}
                                          onClick={() =>
                                            handleSelectedDocAction(
                                              "sendRegularMessage",
                                              doc,
                                            )
                                          }
                                        >
                                          <img
                                            src={whatsappChatSmallIcon}
                                            alt=""
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {!docsList?.length && docsListReady && (
                          <div
                            style={{
                              position: "absolute",
                              right: 16,
                              top: 56,
                              width: "100%",
                            }}
                          >
                            {localization.docsList.noDocuments}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {windowStep === "templateChoose" && (
            <>
              <TemplatesMenu
                templatesLoading={false}
                templatesList={templatesWithDocFile}
                whatsappTemplatesList={templatesList.whatsappTemplates}
                languages={templatesList.languages}
                closeAccordion={() => {}}
                chatUserName={""}
                currentContact={""}
                globalID={globalID}
                handleSetNotif={handleSetNotif}
                setTextareaValue={() => {}}
                textareaRef={null}
                resetTextArea={() => {}}
                templatesWithSend={false}
                noSendHandleFunc={(templatesObj) => {
                  setSelectedTemplate(templatesObj.sendObject);
                  selectedTemplateRef.current = templatesObj.sendObject;
                }}
                noUpload={true}
                // existingFile={docInfoRef.current.previewImageLink.pdfLinkString}
                existingFile={
                  docInfo?.previewImageLink?.pdfLinkString
                    ? docInfo.previewImageLink.pdfLinkString
                    : null
                }
                fetchWhatsappTemplates={fetchWhatsappTemplates}
              />

              <div
                style={{ display: "flex", gap: 15, justifyContent: "flex-end" }}
              >
                <button
                  className="funner-priority-btn funner-priority-btn--outline"
                  onClick={closeTemplateChoose}
                >
                  {localization.templateChoose.buttons.close}
                </button>
                <button
                  className="funner-priority-btn funner-priority-btn--fill"
                  onClick={handleSendInChooseTemplate}
                  disabled={!selectedTemplate || documentMediaLoading}
                >
                  {localization.templateChoose.buttons.action}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default PrioritySendDocsChat;
