export function IsJsonString(str) {
  if (!str) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
export function isEnglishString(str) {
  const englishRegex = /^[a-zA-Z0-9\s.,!?]+$/;

  return englishRegex.test(str);
}
export function isHebrewString(str) {
  const hebrewRegex = /[\u0590-\u05FF]+/;
  return hebrewRegex.test(str);
}

export function addEventListenerOnce(element, event, func) {
  if (!element._eventListeners) {
    element._eventListeners = {};
  }

  if (!element._eventListeners[event]) {
    element.addEventListener(event, func);
    element._eventListeners[event] = true;
  }
}
export function getContrastYIQ(hexcolor) {
  const r = parseInt(hexcolor.substr(1, 2), 16);
  const g = parseInt(hexcolor.substr(3, 2), 16);
  const b = parseInt(hexcolor.substr(5, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 128 ? "black" : "white";
}

export function convertSecondstoTime(given_seconds) {
  const dateObj = new Date(given_seconds * 1000);
  // const hours = dateObj.getUTCHours();
  const minutes = dateObj.getUTCMinutes();
  const seconds = dateObj.getSeconds();

  const timeString =
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");

  return timeString;
}

export const isUpdateTokenPage = () => {
  const currentURL = window.location.href;
  return currentURL.includes("app/settings/integration/apiforms");
};

export function searchObjectInArray(key, value, myArray) {
  ////console.log(key, value, myArray, 'searchObjectInArrayFunc')
  for (let i = 0; i < myArray.length; i++) {
    if (myArray[i][key] == value) {
      return myArray[i];
    }
  }
  ////console.log(myArray, "does not have this key or value", key,value)
}
export function changeNumber(number) {
  if (!number) return;
  const clearedNumber = number.replace(/\D/g, "");
  if (clearedNumber.length < 12) {
    const numberWithCountryCode = "972" + clearedNumber.slice(1);
    return numberWithCountryCode;
  } else {
    return clearedNumber;
  }
}

export function getElementOffset(el) {
  const box = el.getBoundingClientRect();
  console.log(box);

  return {
    top: box.top + window.scrollY,
    right: box.right + window.scrollX,
    bottom: box.bottom + window.scrollY,
    left: box.left + window.scrollX,
  };
}

export function getGlobalScripsData() {
  return new Promise((resolve, reject) => {
    let getScripts = setInterval(() => {
      const scripts = document.querySelectorAll("script");
      if (scripts) {
        [...scripts].map((script) => {
          if (
            script.textContent.includes("window.dataLayer = window.dataLayer")
          ) {
            const scriptString = String(script.textContent);
            // const arr = scriptString.split('(');
            // if (arr) {
            // const obj = JSON.parse(arr[1].slice(0, -1));
            // const userglobalid = obj.userglobalid;
            let userGlobalIdMatch = scriptString.match(
              /"userglobalid":\s*"([^"]*)"/,
            );
            let userGlobalId = userGlobalIdMatch ? userGlobalIdMatch[1] : null;

            let companyIdMatch = scriptString.match(/"companyid":\s*"([^"]*)"/);
            let companyId = companyIdMatch ? companyIdMatch[1] : null;

            let companyNameMatch = scriptString.match(
              /"copmanyname":\s*"((?:[^"\\]|\\.)*)"/,
            );
            let companyName = companyNameMatch
              ? companyNameMatch[1].replace(/\\\"/g, '"')
              : null;

            let userFullNameMatch = scriptString.match(
              /"userfullname":\s*"((?:[^"\\]|\\.)*)"/,
            );
            let userFullName = userFullNameMatch
              ? userFullNameMatch[1].replace(/\\\"/g, '"')
              : null;

            if (userGlobalId) {
              clearInterval(getScripts);

              // Resolve the promise with the obtained data
              resolve({
                globalID: userGlobalId,
                companyID: companyId,
                companyName: companyName,
                userfullname: userFullName,
              });
            }
            // }
          }
        });
      }
    }, 10);
  });
}

export function filterUniqueUsers(users) {
  const uniqueUsers = [];
  const phoneNumbers = new Set();

  users.forEach((user) => {
    if (!phoneNumbers.has(user.UserDisplayPhoneNumber)) {
      uniqueUsers.push(user);
      phoneNumbers.add(user.UserDisplayPhoneNumber);
    }
  });

  return uniqueUsers;
}

export const displayMessageDate = (date) => {
  const targetDate = new Date(date);
  const currentDate = new Date();
  targetDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  const timeDifferenceInDays =
    (currentDate - targetDate) / (24 * 60 * 60 * 1000);

  return timeDifferenceInDays;
};

export const getFileType = (str) => {
  if (!str) return null;
  const splitFileName = str.split(".");
  const fileType = splitFileName[splitFileName.length - 1].toLowerCase();
  return fileType;
};

export function generateUniqueId(existingIds = [], length = 5) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;

  let uniqueId = "";
  let isUnique = false;

  while (!isUnique) {
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (!existingIds.includes(result)) {
      uniqueId = result;
      isUnique = true;
    }
  }

  return uniqueId;
}

export function isEmptyObject(obj) {
  for (var i in obj) {
    if (obj.hasOwnProperty(i)) {
      return false;
    }
  }
  return true;
}

export function getCurrentDateFormatted() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}`;
}

export const getDateParts = (dateParam = null) => {
  const date = dateParam || new Date();
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear());
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  const milliseconds = String(date.getMilliseconds()).padStart(7, "0");

  return {
    day,
    month,
    year,
    hours,
    minutes,
    seconds,
    milliseconds,
  };
};

export const getInitials = (name) => {
  const names = name.split(' ');
  const initials = names.map((n) => n[0]).join('');
  return initials.toUpperCase();
};