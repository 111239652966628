import React from "react";

// import "./styles/old.css";
// import "./styles/style.scss";
import { createRoot } from "react-dom/client";
import { applicationType } from "./applicationType.js";
import App from "./App.jsx";

const renderRootComponent = async (element) => {
  // await require("./styles/old.css");
  await require("./styles/old.scss");
  await require("./styles/style.scss");

  const insertNode = document.createElement("div");
  insertNode.className = "root-whatsapp rtl chat w-0";
  if (applicationType === "extension") {
    const extensionAddtionalWrapper = document.createElement("div");
    extensionAddtionalWrapper.classList.add(
      "_funner-global-messanger-container",
    );
    extensionAddtionalWrapper.appendChild(insertNode);
    element.appendChild(extensionAddtionalWrapper);
  } else {
    element.appendChild(insertNode);
  }

  const root = createRoot(insertNode);
  root.render(<App />);
};

if (
  !(
    applicationType === "extension" &&
    (document.body.classList.contains("funner-global-web-app-page") ||
      document.documentElement.classList.contains("funner-global-web-app-page"))
  )
) {
  // renderRootComponentOnMount(".root-whatsapp.chat");
  renderRootComponent(
    applicationType === "extension" ? document.documentElement : document.body,
  );
}

window.addEventListener("error", (e) => {
  if (
    e.message === "ResizeObserver loop limit exceeded" ||
    e.message ===
      "ResizeObserver loop completed with undelivered notifications."
  ) {
    const resizeObserverErrDiv = document.getElementById(
      "webpack-dev-server-client-overlay-div",
    );
    const resizeObserverErr = document.getElementById(
      "webpack-dev-server-client-overlay",
    );
    if (resizeObserverErr) {
      resizeObserverErr.setAttribute("style", "display: none");
    }
    if (resizeObserverErrDiv) {
      resizeObserverErrDiv.setAttribute("style", "display: none");
    }
  }
});
