import React, { useEffect, useState } from "react";
import { applicationType } from "../applicationType";
import { errorIcon, hintLogo } from "../libs/images";

function HintNotif({
  text,
  phone,
  fireberryAccountLink,
  notifHintTimeout,
  whatsappUsername,
  clickOnMessageOpen,
  targetUser,
  openChatByContact,
  linkToObjectNum,
}) {
  const [isOpen, setOpen] = useState(true);
  const [number, setNumber] = useState("");
  const [textSlice, setTextSlice] = useState("");

  useEffect(() => {
    if (phone) {
      setNumber(() => "0" + phone.slice(3));
    }
    if (text) {
      setTextSlice(text.slice(0, 60));
    }
  }, [text, phone]);

  function closeHint() {
    setOpen(false);

    if (applicationType === "extension") {
      // eslint-disable-next-line no-undef
      chrome.runtime.sendMessage({ type: "closeHintNotif" });
    }
  }

  useEffect(() => {
    let cancel = false;
    // const setTimeOut = getFromLocal('notifHintTimeout');
    let timeout = notifHintTimeout;

    //console.log(timeout,'timeout')
    if (timeout !== 0 && timeout !== null) {
      setTimeout(() => {
        if (cancel) return;
        setOpen(false);
      }, timeout * 1000);
    }

    return () => {
      cancel = true;
    };
  }, []);

  return isOpen ? (
    <div className="general-hint general-hint-notification active">
      <div className="general-hint__body">
        <span className="close-hint-btn" onClick={closeHint}>
          X
        </span>
        <div onClick={closeHint} className="general-hint__content link">
          <a
            target="_blank"
            href={
              fireberryAccountLink
                ? fireberryAccountLink
                : `https://app.powerlink.co.il/app/views/${
                    linkToObjectNum === "second" ? "2" : "1"
                  }?q=${number}`
            }
            className="general-hint__icon"
            rel="noreferrer"
          >
            <img src={hintLogo} alt="" className="image" />
            <img src={errorIcon} alt="" className="error" />
          </a>
          <div
            style={clickOnMessageOpen ? { cursor: "pointer" } : null}
            onClick={() =>
              clickOnMessageOpen ? openChatByContact(targetUser) : null
            }
          >
            <p className="general-hint__text general-hint__link">
              {whatsappUsername + " " + number}
            </p>
            <p>{textSlice}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default HintNotif;
