/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { applicationType } from "../applicationType";
const messageSound =
  applicationType === "extension"
    ? chrome.runtime.getURL("assets/message_sound_new.mp3")
    : "assets/message_sound_new.mp3";

export default function AudioNotification({ notifSound, isNotifOn, volume }) {
  useEffect(() => {
    if (notifSound.current) {
      notifSound.current.volume = volume;
    } else {
      const waitForSoundRef = setInterval(() => {
        if (notifSound.current) {
          notifSound.current.volume = volume;
          clearInterval(waitForSoundRef);
        }
      }, 20);
    }
  }, [volume]);

  if (isNotifOn) {
    return (
      <audio
        ref={notifSound}
        // src="https://assets.mixkit.co/active_storage/sfx/2354/2354-preview.mp3"
        src={messageSound}
        // autoPlay
      />
    );
  }

  return null;
}
