import React, { useState, useRef, useEffect } from "react";

function MaterialDropdown({
  optionsList,
  additionalClass,
  arrowIcon,
  handleSelectOptionFunction,
  selectedValue,
  placeholderValue,
  additionalParams,
  controlledValue,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(selectedValue);
  const [additionalClassList, setAdditionalClassList] = useState(
    additionalClass.split(" "),
  );
  const dropdownRef = useRef(null);

  const handleOptionClick = (option) => {
    if (
      additionalClassList.includes(
        "settings-window-builder__dropdown-with-icons",
      ) &&
      !controlledValue
    ) {
      setSelectedOption(option.name);
    } else if (
      !additionalClassList.includes(
        "settings-window-builder__condition-dropdown",
      ) &&
      !controlledValue
    ) {
      setSelectedOption(option);
    }

    requestAnimationFrame(() => setIsOpen(false));

    if (additionalParams) handleSelectOptionFunction(option, additionalParams);
    else handleSelectOptionFunction(option);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  function getContrastYIQ(hexcolor) {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(5, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 128 ? "black" : "white";
  }

  useEffect(() => {
    window.addEventListener("click", handleOutsideClick);
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (
      additionalClassList.includes(
        "settings-window-builder__condition-dropdown",
      )
    ) {
      setSelectedOption(selectedValue);
    }
    if (controlledValue) {
      setSelectedOption(selectedValue);
    }
  }, [selectedValue]);

  return (
    <div
      className={`${additionalClass} material-dropdown material-dropdown-common ${
        isOpen ? "active" : ""
      }`}
      ref={dropdownRef}
      style={
        additionalClassList.includes(
          "settings-window-builder__text-dropdown--addTags",
        )
          ? { pointerEvents: "none" }
          : null
      }
    >
      {additionalClassList.includes("unread-messages-popup__labels-filter") ||
      additionalClassList.includes("unread-messages-popup__labels-filter") ? (
        <div
          className="material-dropdown__selected"
          ref={(el) => {
            const backgroundColor = selectedOption.color;
            const textColor = getContrastYIQ(backgroundColor);
            el &&
              el.style.setProperty(
                "background-color",
                `${backgroundColor}`,
                "important",
              );
            el && el.style.setProperty("color", `${textColor}`, "important");
          }}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption.name}
          <span className="icon">
            {/* <img src={arrowIcon} alt="arrow" /> */}
            <svg
              width={20}
              height={21}
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.825 7.4126L10 11.2293L6.175 7.4126L5 8.5876L10 13.5876L15 8.5876L13.825 7.4126Z"
                fill={getContrastYIQ(selectedOption.color)}
              />
            </svg>
          </span>
        </div>
      ) : additionalClassList.includes("settings-window-builder__dropdown") ? (
        <div
          className="material-dropdown__selected"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="material-dropdown__text-icon">
            {selectedOption?.icon ? (
              <span className="_icon-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedOption.icon,
                  }}
                />
              </span>
            ) : (
              ""
            )}

            <span className="_text">
              {selectedOption ? selectedOption.name : placeholderValue}
            </span>
          </div>

          <span className="icon">
            <img src={arrowIcon} alt="arrow" />
          </span>
        </div>
      ) : additionalClassList.includes(
          "settings-window-builder__dropdown-with-icons",
        ) ? (
        <div
          className={`material-dropdown__selected ${
            !optionsList.some((item) => item.name === selectedOption)
              ? "_placeholder"
              : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="material-dropdown__text-icon">
            {selectedOption.icon ? (
              <span className="_icon-text">
                <div
                  dangerouslySetInnerHTML={{
                    __html: selectedOption.icon,
                  }}
                />
              </span>
            ) : (
              ""
            )}

            <span className="_text">{selectedOption}</span>
          </div>

          <span className="icon">
            <img src={arrowIcon} alt="arrow" />
          </span>
        </div>
      ) : additionalClassList.includes(
          "settings-window-builder__text-dropdown",
        ) ? (
        <div
          className={`material-dropdown__selected ${
            !optionsList.some((item) => item?.name === selectedOption?.name)
              ? "_placeholder"
              : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption ? selectedOption.name : ""}
          <span className="icon">
            <img src={arrowIcon} alt="arrow" />
          </span>
        </div>
      ) : (
        <div
          className={`material-dropdown__selected ${
            !optionsList.includes(selectedOption) ? "_placeholder" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          {selectedOption}
          <span className="icon">
            <img src={arrowIcon} alt="arrow" />
          </span>
        </div>
      )}

      {isOpen && (
        <ul className="material-dropdown__body">
          {additionalClassList.includes(
            "unread-messages-popup__labels-filter",
          ) ||
          additionalClassList.includes(
            "unread-messages-popup__labels-filter",
          ) ? (
            <>
              {optionsList?.map((label, index) => (
                <li
                  key={label.name}
                  ref={(el) => {
                    const backgroundColor = label.color;
                    const textColor = getContrastYIQ(backgroundColor);
                    el &&
                      el.style.setProperty(
                        "background-color",
                        `${backgroundColor}`,
                        "important",
                      );
                    el &&
                      el.style.setProperty(
                        "color",
                        `${textColor}`,
                        "important",
                      );
                  }}
                  onClick={() => handleOptionClick(label)}
                >
                  {label.name}
                </li>
              ))}
            </>
          ) : additionalClassList.includes(
              "settings-window-builder__dropdown",
            ) ? (
            <>
              {optionsList.map((option, index) => (
                <li key={option.name} onClick={() => handleOptionClick(option)}>
                  <div className="material-dropdown__text-icon">
                    <span className="_icon-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: option.icon,
                        }}
                      />
                    </span>
                    <span className="_text">{option.name}</span>
                  </div>
                </li>
              ))}
            </>
          ) : additionalClassList.includes(
              "settings-window-builder__dropdown-with-icons",
            ) ? (
            <>
              {optionsList.map((option, index) => (
                <li
                  key={option.name}
                  onClick={() =>
                    option.status === "dev" ? null : handleOptionClick(option)
                  }
                >
                  <div
                    className={`material-dropdown__text-icon ${
                      option.status === "dev" ? "_disabled-element" : ""
                    }`}
                    data-hint={option.status === "dev" ? "dev" : null}
                  >
                    <span className="_icon-text">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: option.icon,
                        }}
                      />
                    </span>
                    <span className="_text">{option.name}</span>
                  </div>
                </li>
              ))}
            </>
          ) : additionalClassList.includes(
              "settings-window-builder__text-dropdown",
            ) ? (
            optionsList.map((option, index) => (
              <li
                key={option.name}
                onClick={() => {
                  if (
                    // (additionalClass.includes(
                    //   "settings-window-builder__text-dropdown--sendMessage",
                    // ) &&
                    //   index === 1) ||
                    option.status &&
                    option.status === "dev"
                  ) {
                  } else {
                    handleOptionClick(option);
                  }
                }}
                data-hint={
                  // (additionalClass.includes(
                  //   "settings-window-builder__text-dropdown--sendMessage",
                  // ) &&
                  //   index === 1) ||
                  option.status && option.status === "dev" ? "dev" : null
                }
                className={
                  // (additionalClass.includes(
                  //   "settings-window-builder__text-dropdown--sendMessage",
                  // ) &&
                  //   index === 1) ||
                  option.status && option.status === "dev"
                    ? "_disabled-element"
                    : null
                }
              >
                {option.name}
              </li>
            ))
          ) : (
            optionsList.map((option, index) => (
              <li key={option} onClick={() => handleOptionClick(option)}>
                {option}
              </li>
            ))
          )}
        </ul>
      )}
    </div>
  );
}

export default MaterialDropdown;
