import React from "react";

function WarningMessageTemplate({
  templateType,
  iconSrc,
  linkHref,
  linkText,
  messageText,
}) {
  return (
    <div className={`chat__warning-message-wrapper active ${templateType}`}>
      <div className="chat__warning-message">
        <div className="chat__warning-message-icon">
          <img src={iconSrc} alt="" />
        </div>
        <div className="chat__warning-message-content">
          {linkHref && linkText ? (
            <a href={linkHref} className="chat__warning-message-text">
              {linkText}
            </a>
          ) : (
            <p className="chat__warning-message-text">{messageText}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default WarningMessageTemplate;
