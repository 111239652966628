import React, { useEffect, useState } from "react";
import localizations from "../../localizations.json";
import { funnerLogo } from "../../libs/images";
import ResetPassword from "./AuthWindows/ResetPassword";
import {
  UseFetchGetAnonymos,
  UseFetchPost,
  UseFetchPostAnonymos,
} from "../../hooks/fetchFunctions";
import Login from "./AuthWindows/Login";
import { applicationType } from "../../applicationType";
import VerifyOTP from "./AuthWindows/VerifyOTP";
import Logout from "./AuthWindows/Logout";
import GetToken from "./AuthWindows/GetToken";
import Register from "./AuthWindows/Register";
import { isUpdateTokenPage } from "../../libs/helpers";

function AuthPopup({
  isOnlyResetPass,
  setIsNeedResetPassPopup,
  openAuthPopup,
  setOpenAuthPopup,
  isPowerLinkPage,
  userData,
  userDataRef,
  handleSetNotif,
  authPopupType,
  setAuthPopupType,
  handleSetToken,
  handleRemoveToken,
  openChatAuthControl,
  handleToggleChatFromAuth,
  token,
}) {
  const [localization, setLocalization] = useState(localizations["he"].auth);

  //! Data
  const [popupTitle, setPopupTitle] = useState("");
  const [loginData, setLoginData] = useState({
    phone: "",
    password: "",
  });
  const [loginErrors, setLoginErrors] = useState({
    phone: null,
    password: null,
  });
  const [loginProcessing, setLoginProcessing] = useState(false);
  const [otpData, setOtpData] = useState({
    otp: "",
    phone: "",
  });
  const [resetPasswordData, setResetPasswordData] = useState({
    passwordUpdate: "",
    passwordUpdate2: "",
  });
  const [validationOptions, setValidationOptions] = useState({
    // lowerCaseLetter: false,
    // number: false,
    // specialSign: false,
    samePassword: false,
    minimumCharacters: false,
    // upperCaseLetter: false,
    confirmRegulations: false,
  });
  const [registerData, setRegisterData] = useState({
    powerlinkToken: "",
    email: "",
    password: "",
    verifyPassword: "",
    phoneNumberLogin: "",
    firstName: "",
    lastName: "",
    companyName: "",
    companyPassCode: "",
  });

  //! View handlers
  const handleAuthPopupWindow = (state) => {
    setAuthPopupType(state);
    setValidationOptions({
      samePassword: false,
      minimumCharacters: false,
      confirmRegulations: false,
    });
  };
  const handleCloseTokenPopup = () => {
    setOpenAuthPopup(false);
    if (authPopupType === "verifyOTP") {
      handleAuthPopupWindow("login");
    }
  };

  //! Handlers
  const handleLoginParams = (params) => {
    setLoginData((prevObj) => {
      return { ...prevObj, ...params };
    });
  };
  const handleLoginErrors = (params) => {
    setLoginErrors((prevObj) => ({ ...prevObj, ...params }));
  };
  const handleOtpParams = (params) => {
    setOtpData((prevObj) => {
      return { ...prevObj, ...params };
    });
  };
  const handleResetPasswordParams = (params) => {
    setResetPasswordData((prevObj) => {
      return { ...prevObj, ...params };
    });
  };
  const handleRegisterParams = (params) => {
    setRegisterData((prevObj) => {
      return { ...prevObj, ...params };
    });
  };

  //! Async functions
  async function sendOTP() {
    const res = await UseFetchGetAnonymos(
      `/api/auth/resendOTP/${userDataRef.current.Udid}`,
      undefined,
      null,
      undefined,
      "config",
    );
    return res;
  }
  async function sendOTPByPhone(phone) {
    const res = await UseFetchGetAnonymos(
      `/api/auth/resendOTPByPhone/${phone}`,
      undefined,
      null,
      undefined,
      "config",
    );
    return res;
  }
  const handleLoginStep = async (phone, password = null) => {
    if (!/^\d{10}$/.test(phone)) {
      handleLoginErrors({
        phone: localization.login.errors.wrongFormat,
      });
      return setLoginProcessing(false);
    }

    setLoginProcessing(true);
    const userInfoByPhone = await UseFetchGetAnonymos(
      `/api/services/GetFunnerUserStatus`,
      {
        phoneNumber: phone,
      },
      null,
      undefined,
      "config",
    );

    if (!userInfoByPhone?.data?.result) {
      handleLoginErrors({ phone: localization.login.errors.userDoesntExist });
      return setLoginProcessing(false);
    }

    if (
      userInfoByPhone.data.result &&
      !userInfoByPhone.data.isOTPverified
      // && userInfoByPhone.data.companyName.length
    ) {
      try {
        setAuthPopupType("verifyOTP");
        handleOtpParams({
          phone: phone,
          password: password,
        });
        const sendOtpRes = await sendOTPByPhone(phone);
        if (sendOtpRes?.data) {
          const response = await sendOtpRes?.data.json();
        }
      } catch (error) {
        console.log("GetFunnerUserStatus error: ", error);
      }

      handleSetNotif(
        "You must verify OTP to login with this phone number",
        "error",
      );
      return setLoginProcessing(false);
    }

    try {
      const loginRes = await UseFetchPostAnonymos(
        "/api/auth/loginStandalone",
        {
          phone: phone,
          password: password,
        },
        "config",
      );
      console.log(loginRes);
      if (loginRes.status === 204) {
        setLoginProcessing(false);
        return handleLoginErrors({
          password: localization.login.errors.wrongPass,
        });
        // return handleSetNotif(localization.login.wrongDataNotif, "error");
      }
      if (loginRes?.data) {
        const loginToken = loginRes.data;
        if (typeof loginToken === "string") {
          if (loginToken.length > 0) {
            handleSetToken(loginToken);
            if (applicationType !== "web") setOpenAuthPopup(false);
            handleSetNotif(localization.notifications.login.success, "success");

            if (applicationType === "web") window.location.reload();
          } else {
            handleSetNotif(localization.notifications.login.error, "error");
          }
        }
      }
      setLoginProcessing(false);
    } catch (error) {
      console.log("loginStandalone error: ", error);
      handleSetNotif(localization.notifications.login.error, "error");
      setLoginProcessing(false);
    }
  };
  function handleLogouStep() {
    handleRemoveToken();
    handleAuthPopupWindow("login");

    // const hintNotifWrapper = document.querySelector(".hint-notif-container");
    // if (hintNotifWrapper) {
    //   ReactDOM.unmountComponentAtNode(hintNotifWrapper);
    // }
  }
  const getPowerLinkToken = () => {
    setOpenAuthPopup(false);
    setAuthPopupType("register");
    setPopupTitle("הרשמה");
    // updateUser();
  };

  function addBackgroundListener() {
    if (applicationType === "extension") {
      // eslint-disable-next-line no-undef
      chrome.runtime.onMessage.addListener(function (
        message,
        sender,
        sendResponse,
      ) {
        if (message.type === "extensionPopupLogin") {
          const params = message.content;
          handleLoginStep(params.phone, params.password);
        } else if (message.type === "extensionPopupLogout") {
          handleLogouStep();
        } else if (message.type === "openResetPassword") {
          setOpenAuthPopup(true);
          setAuthPopupType("resetPassword");
        } else if (message.type === "openRegister") {
          setOpenAuthPopup(true);
          setAuthPopupType("register");
        }
      });
    }
  }
  const setCompanyPassCodeFromURL = () => {
    const currentLink = window.location.search;
    const searchParams = new URLSearchParams(currentLink);
    const paramName = "companyPassCode";
    const companyPassCodeLink = searchParams.get(paramName);

    if (companyPassCodeLink) {
      setRegisterData({
        companyPassCode: companyPassCodeLink,
      });
      handleAuthPopupWindow("register");
    }
  };

  useEffect(() => {
    addBackgroundListener();
    setCompanyPassCodeFromURL();
  }, []);

  return openAuthPopup || isOnlyResetPass ? (
    <div id="main-popup" aria-hidden="false" className="main-popup popup_show">
      <div className="main-popup__wrapper ">
        <div
          className="get-token-popup"
          style={{
            backgroundColor: "white",
            overflow: "auto",
            maxHeight: "100vh",
          }}
        >
          <div className="get-token-popup__body">
            {isOnlyResetPass ? (
              <>
                <div className="get-token-popup__header main-popup__header">
                  <h4 className="main-popup__title">
                    <span className="main-popup__title-icon">
                      <img src={funnerLogo} alt="" />
                    </span>
                    {localizations["he"].isUpdatePasswordHint}
                  </h4>
                </div>
              </>
            ) : (
              <>
                <div className="get-token-popup__header main-popup__header">
                  <h4 className="main-popup__title">
                    <span className="main-popup__title-icon">
                      <img src={funnerLogo} alt="" />
                    </span>
                    {popupTitle}
                  </h4>
                  <button
                    data-close
                    type="button"
                    className="main-popup__close-btn"
                    onClick={handleCloseTokenPopup}
                  >
                    ×
                  </button>
                </div>
                {authPopupType === "login" && (
                  <Login
                    isPowerLinkPage={isPowerLinkPage}
                    loginData={loginData}
                    handleLoginParams={handleLoginParams}
                    handleAuthPopupWindow={handleAuthPopupWindow}
                    handleLoginStep={handleLoginStep}
                    openChatAuthControl={openChatAuthControl}
                    handleToggleChatFromAuth={handleToggleChatFromAuth}
                    token={token}
                    handleSetNotif={handleSetNotif}
                    loginProcessing={loginProcessing}
                    loginErrors={loginErrors}
                    handleLoginErrors={handleLoginErrors}
                  />
                )}
                {authPopupType === "logout" && (
                  <Logout
                    userData={userData}
                    handleLogouStep={handleLogouStep}
                    isPowerLinkPage={isPowerLinkPage}
                    handleAuthPopupWindow={handleAuthPopupWindow}
                    handleSetNotif={handleSetNotif}
                    openChatAuthControl={openChatAuthControl}
                    handleToggleChatFromAuth={handleToggleChatFromAuth}
                    token={token}
                  />
                )}

                {authPopupType === "register" && (
                  <Register
                    handleAuthPopupWindow={handleAuthPopupWindow}
                    isPowerLinkPage={isPowerLinkPage}
                    registerData={registerData}
                    handleRegisterParams={handleRegisterParams}
                    validationOptions={validationOptions}
                    setValidationOptions={setValidationOptions}
                    userData={userData}
                    handleSetNotif={handleSetNotif}
                    sendOTPByPhone={sendOTPByPhone}
                    handleOtpParams={handleOtpParams}
                  />
                )}

                {authPopupType === "getToken" && (
                  <GetToken getPowerLinkToken={getPowerLinkToken} />
                )}
                {(authPopupType === "resetPassword" || isOnlyResetPass) && (
                  <ResetPassword
                    isOnlyResetPass={isOnlyResetPass}
                    isPowerLinkPage={isPowerLinkPage}
                    sendOTP={sendOTP}
                    sendOTPByPhone={sendOTPByPhone}
                    handleSetNotif={handleSetNotif}
                    authPopupType={authPopupType}
                    handleAuthPopupWindow={handleAuthPopupWindow}
                    otpData={otpData}
                    handleOtpParams={handleOtpParams}
                    userData={userData}
                    resetPasswordData={resetPasswordData}
                    handleResetPasswordParams={handleResetPasswordParams}
                    validationOptions={validationOptions}
                    setValidationOptions={setValidationOptions}
                    setPopupTitle={setPopupTitle}
                    handleLogouStep={handleLogouStep}
                  />
                )}

                {authPopupType === "verifyOTP" && (
                  <VerifyOTP
                    otpData={otpData}
                    handleOtpParams={handleOtpParams}
                    isPowerLinkPage={isPowerLinkPage}
                    handleSetNotif={handleSetNotif}
                    handleLoginStep={handleLoginStep}
                    handleAuthPopupWindow={handleAuthPopupWindow}
                    sendOTPByPhone={sendOTPByPhone}
                    setPopupTitle={setPopupTitle}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}

export default AuthPopup;
