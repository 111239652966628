import React from "react";

function MediaPopup({ mediaPopupView, handleMediaPreview }) {
  const closePreviewBackground = (e) => {
    if (!e.target.closest(".media-popup-preview__content"))
      handleMediaPreview({ visible: false, file: null });
  };
  const closePreview = () => {
    handleMediaPreview({ visible: false, file: null });
  };

  return (
    <div className="media-popup-preview" onClick={closePreviewBackground}>
      <div className="media-popup-preview__wrapper">
        <div className="media-popup-preview__content">
          <div
            className="media-popup-preview__close funner-icon"
            onClick={closePreview}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                fill="black"
                fill-opacity="0.54"
              />
            </svg>
          </div>
          <a
            className="media-popup-preview__media-container"
            href={mediaPopupView.file}
            target="_blank"
            rel="noreferrer"
          >
            <img src={mediaPopupView.file} alt="" />
          </a>

          {/* <div style={{ display: "flex", gap: 15, justifyContent: "flex-end" }}>
            <button
              className="funner-priority-btn funner-priority-btn--outline"
              onClick={closePreview}
            >
              {localization.docPreview.buttons.close}
            </button>
            <button
              className="funner-priority-btn funner-priority-btn--fill"
              onClick={handleSendInPreview}
              style={{ margin: 0 }}
            >
              {localization.docPreview.buttons.action}
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default MediaPopup;
